import { Flex, Text, Tr, Td } from "@chakra-ui/react";

type Props = {
  span: number;
};

function NoData({ span }: Props) {
  return (
    <Tr>
      <Td colSpan={span}>
        <Flex w="full" justifyContent="center">
          <Text fontSize={16} m={4}>
            No data available
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}

export default NoData;
