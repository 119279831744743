import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import StandardButton from 'components/buttons/StandardButton';
import { ErrorToast } from 'components/toast';
import { useState } from 'react';
import { copyToClipboard } from 'utils/domUtils';

interface ViewInfoModalProps {
  title: string;
  info: string | undefined;
}

function ViewInfoModal({ title, info }: ViewInfoModalProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [copied, setCopied] = useState(false);
  const toast = useToast();

  const handleCopyClick = () => {
    if (typeof info === 'string') {
      copyToClipboard(info)
        .then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 1500);
        })
        .catch(() => {
          toast({
            render: ({ onClose }) => (
              <ErrorToast onClose={onClose} message="Failed to copy text" />
            ),
          });
        });
    }
  };
  return (
    <>
      <VStack bg="white" borderRadius={10} p={4} w="20%" shadow="md">
        <Text fontSize={16} fontWeight="bold">
          {title}
        </Text>
        <StandardButton title="View" w="max-content" onClick={onOpen} />
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="2xl">{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={12}>{info}</Text>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <HStack spacing={10}>
              <StandardButton
                title={copied ? 'Copied!' : 'Copy'}
                w="max-content"
                onClick={handleCopyClick}
              />
              <StandardButton title="Close" w="max-content" onClick={onClose} />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewInfoModal;
