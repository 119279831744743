import { Text, Box, HStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import BackButton from 'components/buttons/BackButton';

type Props = {
  /** Main header title */
  title?: string;

  // Additional info beside title eg. Orders #1122
  extra?: string;

  // Should the print button be shown
  printable?: boolean;

  // Should the back navigation button be shown
  navigateBack?: boolean;

  // The main content
  children?: React.ReactNode;
};

/** Wrapping component for the main content beside the sidebar */
export function BaseContent({
  title,
  extra = '',
  navigateBack = true,
  children,
}: Props) {
  const documentTitle = title ? ` - ${title + ' ' + extra}` : '';

  useEffect(() => {
    document.title = `Ordú Admin${documentTitle}`;
  }, [documentTitle]);

  return (
    <Box bgColor="background" p={[8, 16]} flex={1}>
      <HStack spacing={4}>
        <Text fontSize={28} fontWeight="bold" color="secondary.600">
          {title}
        </Text>
        {extra && (
          <Text color="secondary.300" fontSize="5xl" fontWeight="bold">
            {extra}
          </Text>
        )}
      </HStack>

      <HStack marginY={8} justify="flex-end" alignItems="center">
        {navigateBack && <BackButton />}
      </HStack>
      {children}
    </Box>
  );
}
