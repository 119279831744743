import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Icon,
  Flex,
  Text,
  ButtonGroup,
  Button,
  BoxProps,
  Checkbox,
  HStack,
} from '@chakra-ui/react';
import GlobalSearchFilter from 'components/filters/GlobalSearchFilter';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import NoData from 'components/table/NoData';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  Column,
  CellProps,
  useRowSelect,
  Hooks,
} from 'react-table';
import EntriesSelect from 'components/select/EntriesSelect';
import StandardButton from 'components/buttons/StandardButton';

type RowSelectionProps =
  | {
      /** Boolean that decides whether selecting rows is enabled */
      rowSelectionEnabled?: false;
      rowSelectionAction?: never;
      rowSelectionActionLoading?: never;
    }
  | {
      /** Boolean that decides whether selecting rows is enabled */
      rowSelectionEnabled: true;

      /** Method that passes selected rows */
      rowSelectionAction: ([]) => void;

      /** Boolean that decides if the selection action button isLoading */
      rowSelectionActionLoading: boolean;
    };

type Props<TableData extends Partial<TableData>> = {
  /** Title used in the global filter box  */
  filterTitle?: string;

  /** Column headers for the table */
  columns: Column<TableData>[];

  /** The data to be displayed */
  data: TableData[];

  children?: React.ReactNode;

  /** Boolean that decides whether the search filter is visible */
  search?: boolean;
} & BoxProps &
  RowSelectionProps;

function selectionHook<T extends Partial<T>>(hooks: Hooks<T>) {
  hooks.visibleColumns.push(columns => [
    {
      id: 'selection',
      Cell: ({ row }: CellProps<T>) => (
        <Checkbox
          size="lg"
          colorScheme="primary"
          {...row.getToggleRowSelectedProps()}
        />
      ),
    },
    ...columns,
  ]);
}

/** Primary table component used to display data */
function PrimaryTable<TableData extends Partial<TableData>>({
  filterTitle,
  columns,
  data,
  search = true,
  children,
  rowSelectionEnabled = false,
  rowSelectionAction,
  rowSelectionActionLoading,
  ...props
}: Props<TableData>) {
  const rowSelection = rowSelectionEnabled ? [useRowSelect, selectionHook] : [];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    globalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable<TableData>(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    ...rowSelection,
  );

  return (
    <Box
      boxShadow="md"
      borderRadius={10}
      pt={4}
      bgColor="white"
      sx={{
        '@media print': {
          boxShadow: 'none',
        },
      }}
      {...props}
    >
      {search && (
        <GlobalSearchFilter
          title={filterTitle}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          fontSize={14}
        />
      )}
      <Flex align="center" justify="flex-end" w="full" flexDir="row" p={4}>
        <HStack spacing={2}>
          {children}
          {rowSelectionAction && (
            <StandardButton
              title="Update Selected"
              w="max-content"
              disabled={selectedFlatRows.length === 0}
              isLoading={rowSelectionActionLoading}
              onClick={() => {
                rowSelectionAction(selectedFlatRows.map(row => row.original));
              }}
            />
          )}
        </HStack>
      </Flex>

      <Table {...getTableProps()} fontSize={14} variant="simple">
        <Thead>
          {headerGroups.map(headerGroup => {
            const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
            return (
              <Tr {...headerProps} key={key}>
                {headerGroup.headers.map(column => {
                  const { key, ...columnProps } = column.getHeaderProps(
                    column.getSortByToggleProps(),
                  );
                  return (
                    <Th
                      {...columnProps}
                      key={key}
                      cursor="pointer"
                      fontSize={14}
                      fontFamily="Open Sans"
                      textTransform="capitalize"
                    >
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon ml={4} as={TiArrowSortedDown} />
                        ) : (
                          <Icon ml={4} as={TiArrowSortedUp} />
                        )
                      ) : null}
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {pageCount === 0 ? <NoData span={columns.length} /> : null}
          {page.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => {
                  const { key } = cell.getCellProps();
                  return (
                    <Td {...cell.getCellProps()} key={key}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex p={4} align="center" justify="space-between">
        <Flex align="center">
          <EntriesSelect
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
            value={pageSize}
            w="max-content"
          />
          <Text ml={2} fontSize={14}>
            entries
          </Text>
        </Flex>

        <ButtonGroup
          borderRadius={5}
          borderWidth={1}
          borderColor="secondary.100"
          size="md"
          isAttached
        >
          <Button
            fontSize={14}
            _hover={{ bgColor: 'paginationBg', color: 'white' }}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {`<<`}
          </Button>
          <Button
            fontSize={14}
            _hover={{ bgColor: 'paginationBg', color: 'white' }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Prev
          </Button>
          <Button
            _hover={{ bgColor: 'paginationBg', color: 'white' }}
            fontSize={14}
            bgColor="paginationBg"
            color="white"
          >
            {pageIndex + 1}
          </Button>
          <Button
            fontSize={14}
            _hover={{ bgColor: 'paginationBg', color: 'white' }}
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={!canNextPage}
            d={pageIndex + 2 > pageCount ? 'none' : 'block'}
          >
            {pageIndex + 2}
          </Button>
          <Button
            fontSize={14}
            _hover={{ bgColor: 'paginationBg', color: 'white' }}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </Button>
          <Button
            fontSize={14}
            _hover={{ bgColor: 'paginationBg', color: 'white' }}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {`>>`}
          </Button>
        </ButtonGroup>

        <Text fontSize={14}>
          Page {pageIndex + 1} of {pageCount}
        </Text>
      </Flex>
    </Box>
  );
}

export default PrimaryTable;
