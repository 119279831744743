import { Formik, Form } from 'formik';
import { Stack, Flex } from '@chakra-ui/react';
import SubmitButton from 'components/buttons/SubmitButton';
import LabeledInput from 'components/inputs/LabeledInput';
import { FormProps } from 'types/common';
import { useSubmitHandler } from 'hooks/useSubmitHandler';
import {
  DeploymentConnectionsFormValues,
  defaultValues,
  schema,
} from 'utils/forms/deploymentConnections';

function DeploymentConnectionForm({
  mutation,
  initialValues = defaultValues,
  editing,
}: FormProps<DeploymentConnectionsFormValues>) {
  const submitHandler = useSubmitHandler(
    mutation,
    'deployments',
    'Deployment',
    editing,
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={submitHandler}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form>
          <Stack spacing={10}>
            <Stack
              direction="row"
              p={8}
              bg="white"
              spacing={20}
              borderRadius={5}
            >
              <Stack w="50%" spacing={8}>
                <LabeledInput label="IP Address" fieldName="ip_address" />

                <LabeledInput label="Username" fieldName="username" />
              </Stack>

              <Stack w="50%" spacing={8}>
                <LabeledInput label="Directory" fieldName="directory" />
              </Stack>
            </Stack>
            <Flex justify="flex-end">
              <SubmitButton
                isLoading={mutation.isLoading}
                title="Save"
                isDisabled={!(isValid && dirty)}
              />
            </Flex>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default DeploymentConnectionForm;
