import { Deployment } from 'types/api';
import * as Yup from 'yup';

export type DeploymentConnectionsFormValues = {
  ip_address: string;
  username: string;
  directory: string;
};

export const defaultValues: DeploymentConnectionsFormValues = {
  ip_address: '',
  username: '',
  directory: '',
};

export const schema = Yup.object().shape({
  ip_address: Yup.string(),
  username: Yup.string(),
  directory: Yup.string(),
});

export const parseData = (data: Deployment | undefined) => {
  if (!data) return;

  return {
    ip_address: data.ip_address,
    username: data.username,
    directory: data.directory,
  };
};
