import {
  Button,
  HStack,
  Icon,
  TabList,
  Tabs,
  Text,
  useStyles,
  useTab,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { BiBell } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { HiDocumentReport } from 'react-icons/hi';
import { IconType } from 'react-icons/lib';

type TabProps = {
  /** icon for this tab */
  icon: IconType;

  /** Pass a stricg as children for the title of the tab */
  children: React.ReactNode;
};

type SystemTabsProps = {
  /** Index of the active tab */
  tabIndex: number;

  /** Function to change the tab index */
  handleTabsChange: (index: number) => void;
};

/** Tabs diaplayed on store pages */
function SystemTabs({ tabIndex, handleTabsChange }: SystemTabsProps) {
  const SystemTab = forwardRef<HTMLButtonElement, TabProps>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (props, _ref) => {
      const { icon, ...rest } = props;
      const tabProps = useTab(rest);
      const isSelected = !!tabProps['aria-selected'];

      const styles = useStyles();

      return (
        <Button
          __css={styles.tab}
          {...tabProps}
          _selected={{ color: 'primary.300', borderColor: 'currentColor' }}
        >
          <HStack>
            <Icon color={isSelected ? 'black' : 'secondary.300'} as={icon} />

            <Text
              fontWeight="bold"
              fontSize={14}
              color={isSelected ? 'black' : 'secondary.300'}
            >
              {rest.children}
            </Text>
          </HStack>
        </Button>
      );
    },
  );

  return (
    <Tabs
      index={tabIndex}
      onChange={handleTabsChange}
      size="lg"
      colorScheme="primary"
      variant="line"
    >
      <TabList>
        <SystemTab icon={HiDocumentReport}>Details</SystemTab>
        <SystemTab icon={BiBell}>Incidents</SystemTab>
        <SystemTab icon={FiSettings}>Settings</SystemTab>
      </TabList>
    </Tabs>
  );
}

export default SystemTabs;
