import { Field } from 'formik';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

type Config = {
  /** The filed name of this input */
  name: string;

  /** HTML input type */
  type: string;

  /** Placeholder used while field is empty */
  placeholder: string;
};

type Props = {
  /** Icon to display beside the input */
  icon: React.ReactNode;

  /**
   *  Config object to configure input
   *  @type Config
   */
  config: Config;
};

/** Input used in Login Form */
function LoginInput({ icon, config }: Props) {
  return (
    <InputGroup w="80%" justifyContent="center">
      <InputLeftElement h="14" pointerEvents="none">
        {icon}
      </InputLeftElement>
      <Input
        as={Field}
        name={config.name}
        type={config.type}
        placeholder={config.placeholder}
        fontSize={16}
        focusBorderColor="primary.400"
        h="14"
        bgColor="rgb(246, 243, 249)"
      />
    </InputGroup>
  );
}

export default LoginInput;
