import { BaseContent } from 'components/app';
import ScreenLoading from 'components/app/ScreenLoading';
import AddButton from 'components/buttons/AddButton';
import EditButton from 'components/buttons/EditButton';
import PrimaryTable from 'components/table/PrimaryTable';
import { useGet } from 'hooks/ApiHooks';
import React, { useMemo } from 'react';
import { Column } from 'react-table';
import { System } from 'types/api/system.types';

function SystemsOverview() {
  const name = 'Systems';

  const { isError, isLoading, data } = useGet<System[]>('systems', '/systems');

  const columns = React.useMemo<Column<System>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Deployment',
        accessor: ({ deployment }) => deployment.name,
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        id: 'edit',
        disableSortBy: true,
        Cell: EditButton,
      },
    ],
    [],
  );

  const tableData = useMemo(() => data, [data]);

  return (
    <BaseContent title={name} printable>
      <ScreenLoading isError={isError} isLoading={isLoading}>
        <PrimaryTable
          mt={10}
          filterTitle={name}
          columns={columns}
          data={tableData ?? []}
        >
          <AddButton />
        </PrimaryTable>
      </ScreenLoading>
    </BaseContent>
  );
}
export default SystemsOverview;
