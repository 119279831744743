import { Flex, HStack, Text } from '@chakra-ui/react';
import ScreenLoading from 'components/app/ScreenLoading';
import { useGet } from 'hooks/ApiHooks';
import { useRouter } from 'hooks/useRouter';
import { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { System } from 'types/api';
import SystemEdit from './view/SystemEdit';
import SystemIncidents from './view/SystemIncidents';
import SystemIncidentView from './view/SystemIncidentView';
import SystemSettings from './view/SystemSettings';
import SystemTabs from './view/SystemTabs';

const paths = ['', '/incidents', '/settings'];

function SystemView() {
  const [tabIndex, setTabIndex] = useState(0);
  const { pathname } = useRouter();

  const {
    query: { systemID },
    push,
  } = useRouter();

  const {
    isLoading,
    isError,
    data: system,
  } = useGet<System>(['system', systemID], `systems/${systemID}`);

  useEffect(() => {
    paths.forEach((path, index) => {
      if (pathname.includes(path)) {
        setTabIndex(index);
      }
    });
  }, [pathname]);

  const { path, url } = useRouteMatch();

  const onTabChange = (index: number) => {
    push(`${url}${paths[index]}`);
    setTabIndex(index);
  };

  const title = system?.name ?? '';

  return (
    <ScreenLoading isLoading={isLoading} isError={isError}>
      <Flex bgColor="white" pl={16} justify="space-between">
        <Text fontSize="3xl" fontWeight="bold" color="secondary.600">
          {title}
        </Text>
        <HStack>
          <SystemTabs tabIndex={tabIndex} handleTabsChange={onTabChange} />
        </HStack>
      </Flex>

      {system && (
        <Switch>
          <Route exact path={`${path}`}>
            <SystemEdit system={system} />
          </Route>
          <Route path={`${path}/incidents/:incidentID`}>
            <SystemIncidentView />
          </Route>
          <Route path={`${path}/incidents`}>
            <SystemIncidents />
          </Route>
          <Route path={`${path}/settings`}>
            <SystemSettings />
          </Route>
        </Switch>
      )}
    </ScreenLoading>
  );
}
export default SystemView;
