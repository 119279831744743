import { NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';
import { chakra, Text, HStack } from '@chakra-ui/react';
import { renderIcon } from 'utils/iconProvider';
import { useEffect, useState } from 'react';

const Link = chakra(NavLink);

type Props = {
  name: string;
  to: string;
} & NavLinkProps;

function SidebarLink({ name, ...props }: Props) {
  const [active, setActive] = useState(false);
  const match = useRouteMatch(props.to);

  useEffect(() => {
    if (!match) {
      setActive(false);
      return;
    }

    if (match.path === '/' && !match.isExact) {
      setActive(false);
      return;
    }

    setActive(true);
  }, [match]);

  return (
    <Link
      as={NavLink}
      {...props}
      color="sidebar"
      textDecor="none"
      fontSize="2xl"
      d="flex"
      p={4}
      _activeLink={{ bg: 'black' }}
    >
      <HStack ml="3rem" spacing={10}>
        {renderIcon(props.to, active)}
        <Text color={active ? 'white' : 'sidebar'} pr={4}>
          {name}
        </Text>
      </HStack>
    </Link>
  );
}

export default SidebarLink;
