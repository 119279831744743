import { HStack, Text } from '@chakra-ui/react';

interface Props extends React.ComponentPropsWithoutRef<typeof HStack> {
  title: string;
  info?: string;
}

function InfoText({ title, info = '', ...props }: Props) {
  return (
    <HStack spacing={2} {...props}>
      <Text color="secondary.600" fontWeight="bold">
        {title}
      </Text>
      <Text color="secondary.300">{info}</Text>
    </HStack>
  );
}

export default InfoText;
