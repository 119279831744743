import { FormLabelProps, HStack } from '@chakra-ui/react';
import FormLabelCustom from 'components/inputs/FormLabelCustom';
import InfoTooltip from 'components/tooltip/InfoTooltip';

type Props = {
  /** The title shown beside tooltip */
  label: string;

  /**  The text to be shown hen hovering over the tooltip */
  info?: string;
} & FormLabelProps;

function TitleWithTooltip({ label, info, ...props }: Props) {
  return (
    <HStack spacing={2} align="center">
      <FormLabelCustom {...{ label, ...props }} />
      {info !== undefined ? <InfoTooltip label={info} /> : null}
    </HStack>
  );
}

export default TitleWithTooltip;
