import { HStack, Stack, Text } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import CollapsedText from 'components/text/CollapsedText';
import { DeploymentViewProps } from 'types/common/props.types';
import { DateTime } from 'luxon';

function DeploymentLogs({ deployment }: DeploymentViewProps) {
  return (
    <BaseContent title="Logs">
      <Stack spacing={10}>
        {deployment?.logs.map(log => (
          <Stack key={log.id} bg="white" borderRadius={10} p={4} shadow="md">
            <HStack spacing={16}>
              <HStack>
                <Text fontSize={16} fontWeight="bold">
                  Command:
                </Text>
                <Text fontSize={14}>{log.command}</Text>
              </HStack>

              <HStack>
                {log.code === 0 ? (
                  <Text fontSize={16} color="primary.500" fontWeight="bold">
                    Success
                  </Text>
                ) : (
                  <Text fontSize={16} color="red.500">
                    Failed with Code:${log.code}
                  </Text>
                )}
              </HStack>
            </HStack>
            <HStack>
              <Text fontSize={16} fontWeight="bold">
                Created at:
              </Text>
              <Text fontSize={14}>
                {DateTime.fromISO(log.created_at).toFormat('dd/MM/yyyy HH:mm')}
              </Text>
            </HStack>

            <Stack>
              <CollapsedText command={log.command} text={log.result} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </BaseContent>
  );
}

export default DeploymentLogs;
