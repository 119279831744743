import { Icon, Tooltip, chakra } from '@chakra-ui/react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

type Props = {
  label: string;
};

function InfoTooltip({ label }: Props) {
  if (label) {
    return (
      <Tooltip borderRadius={5} fontSize={12} label={label}>
        <chakra.span>
          <Icon as={AiOutlineQuestionCircle} />
        </chakra.span>
      </Tooltip>
    );
  } else {
    return null;
  }
}

export default InfoTooltip;
