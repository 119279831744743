import { Stack } from '@chakra-ui/react';
import { SettingsData } from 'types/api';
import { SettingInput } from 'components/inputs/SettingInput';
import { SettingGroupsEnums } from 'types/states/SettingGroupsEnums';

type PaymentsAndTransfersProps = {
  settings?: SettingsData[];
};

function PaymentsAndTransfers({ settings }: PaymentsAndTransfersProps) {
  return (
    <Stack spacing={10} bgColor="white" p={12}>
      {settings &&
        settings?.map((setting, index) => {
          if (
            setting.id &&
            setting.group_id == SettingGroupsEnums.PaymentsAndTransfers
          ) {
            return (
              <SettingInput settingProps={settings[index]} key={setting.id} />
            );
          }
        })}
    </Stack>
  );
}
export default PaymentsAndTransfers;
