import { Text, HStack, Stack } from '@chakra-ui/react';
import { Incident } from 'types/api/incidents.types';

function IncidentHeader({ data }: { data: Incident }) {
  const { subject, message, type_name, severity_id, created_at } = data;
  return (
    <Stack>
      <Stack
        color="secondary.600"
        fontSize={16}
        p={12}
        spacing={12}
        align="left"
      >
        <HStack justify="space-between">
          <HStack spacing={4}>
            <Text fontSize={16} fontWeight="bold" color="secondary.600">
              Type:
            </Text>
            <Text fontSize={16} fontWeight="bold" color="secondary.300">
              {type_name}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize={16} fontWeight="bold" color="secondary.600">
              Date:
            </Text>
            <Text fontSize={16} fontWeight="bold" color="secondary.300">
              {created_at}
            </Text>
          </HStack>
          <HStack>
            <Text fontSize={16} fontWeight="bold" color="secondary.600">
              Severity:
            </Text>
            <Text fontSize={16} fontWeight="bold" color="secondary.300">
              {severity_id}
            </Text>
          </HStack>
        </HStack>
        <Stack>
          <Text fontSize={16} fontWeight="bold" color="secondary.600">
            Subject:
          </Text>
          <Text fontSize={14}>{subject}</Text>
        </Stack>
        <Stack>
          <Text fontSize={16} fontWeight="bold" color="secondary.600">
            Message:
          </Text>
          <Text fontSize={14}>{message}</Text>
        </Stack>
      </Stack>
      <hr />
    </Stack>
  );
}

export default IncidentHeader;
