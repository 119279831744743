import { Flex, Stack } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import SubmitButton from 'components/buttons/SubmitButton';
import LabeledInput from 'components/inputs/LabeledInput';
import ApiSelect from 'components/select/ApiSelect';
import FormLabelWrapper from 'components/wrapperUtils/FormLabelWrapper';
import { Form, Formik } from 'formik';
import { useGet, usePost } from 'hooks/ApiHooks';
import { useSubmitHandler } from 'hooks/useSubmitHandler';
import { CustomFormProps } from 'types/common';
import { defaultValues, schema, SystemFormValues } from 'utils/forms/system';

function AddForm({
  mutation,
}: CustomFormProps<SystemFormValues, SystemFormValues>) {
  const submitHandler = useSubmitHandler(mutation, 'systems', 'Systems', false);
  const deployments = useGet('deployments', '/deployments');

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={schema}
      onSubmit={submitHandler}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form>
          <Stack spacing={10}>
            <Stack
              direction="row"
              p={8}
              bg="white"
              spacing={20}
              borderRadius={5}
            >
              <Stack w="50%" spacing={8}>
                <LabeledInput label="Name" fieldName="name" required />

                <FormLabelWrapper
                  label="Deployment"
                  fieldName="deployment_id"
                  required
                >
                  <ApiSelect fieldName="deployment_id" selectData={deployments}>
                    <option value="-1">Select Deployment</option>
                  </ApiSelect>
                </FormLabelWrapper>
              </Stack>

              <Stack w="50%" spacing={8}>
                <LabeledInput label="Description" fieldName="description" />

                <LabeledInput label="Code" fieldName="code" required />
              </Stack>
            </Stack>

            <Flex justify="flex-end">
              <SubmitButton
                isLoading={mutation.isLoading}
                title="Save"
                isDisabled={!(isValid && dirty)}
              />
            </Flex>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function SystemAdd() {
  const mutation = usePost<SystemFormValues>('/systems');

  return (
    <BaseContent title="Add System">
      <Stack spacing={8} mt={10} fontSize={16} color="secondary.600">
        <AddForm mutation={mutation} />
      </Stack>
    </BaseContent>
  );
}

export default SystemAdd;
