import { BiMenuAltLeft } from 'react-icons/bi';
import { AiOutlineDown } from 'react-icons/ai';
import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, Icon } from '@chakra-ui/react';
import { useAuth } from 'hooks/useAuth';

type Props = {
  /** Toggle function for sidebar */
  toggle: () => void;
};

const DEFAULT_NAME = 'Admin User';

/** Header component containing menu toggle and user details */
export function Header({ toggle }: Props) {
  const { logout } = useAuth();
  const { isOpen, onToggle } = useDisclosure();
  const name = DEFAULT_NAME;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      top={0}
      p={4}
      bgColor="white"
      zIndex={10}
    >
      <Icon
        as={BiMenuAltLeft}
        cursor="pointer"
        color="secondary.600"
        boxSize={14}
        onClick={toggle}
      />

      <Menu isOpen={isOpen}>
        <MenuButton
          fontSize="2xl"
          bgColor="white"
          color="secondary.600"
          as={Button}
          rightIcon={<AiOutlineDown size={10} />}
          _hover={{ bgColor: 'secondary.50' }}
          onClick={onToggle}
        >
          {name}
        </MenuButton>
        <MenuList p={4}>
          <Stack spacing={4}>
            {/* <Button
              fontSize="2xl"
              w="full"
              _hover={{ bgColor: 'primary.500', color: 'white' }}
              onClick={() => handleClick('/change-password')}
            >
              Change Password
            </Button> */}
            <Button
              fontSize="2xl"
              w="full"
              bgColor="red.500"
              color="white"
              _hover={{ bgColor: 'red.600' }}
              onClick={logout}
            >
              Logout
            </Button>
          </Stack>
        </MenuList>
      </Menu>
    </Stack>
  );
}
