import { Box, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { AiOutlineClose, AiOutlineCheckCircle } from 'react-icons/ai';

type Props = {
  /** Function to close the popup */
  onClose: () => void;

  /** Name of the effected entity eg. store, menu */
  name: string;

  /** Description of the event that occured eg. updated, added */
  event: string;
};

/** Used to notify the user of a successful network request */
export function SuccessToast({ onClose, name, event: type }: Props) {
  return (
    <Box p={4} color="white" bg="primary.500" borderRadius={5}>
      <Flex justify="space-between">
        <HStack>
          <Icon boxSize={8} as={AiOutlineCheckCircle} color="white" />
          <Text fontSize={16}>Success!</Text>
        </HStack>

        <Icon boxSize={5} as={AiOutlineClose} onClick={onClose} />
      </Flex>

      <Text fontSize={14}>{`${name} ${type}`}</Text>
    </Box>
  );
}
