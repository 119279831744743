import { BaseContent } from 'components/app';
import DisplayIncident from 'components/displayIncident/DisplayIncident';
import { useRouter } from 'hooks/useRouter';

function SystemIncidentView() {
  const {
    query: { systemID, incidentID },
  } = useRouter();

  return (
    <BaseContent title="Incident" extra={` #${incidentID}`}>
      <DisplayIncident systemID={systemID} incidentID={incidentID} />
    </BaseContent>
  );
}

export default SystemIncidentView;
