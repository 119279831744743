import { Text, HStack, Stack } from '@chakra-ui/react';
import { Incident } from 'types/api/incidents.types';

function IncidentAlerted({ data }: { data: Incident }) {
  const { admins_alerted, managers_alerted } = data;
  return (
    <Stack>
      <Stack p={12} justify="space-between">
        <HStack spacing={4}>
          <Text fontSize={16} fontWeight="bold" color="secondary.600">
            Admins Alerted:
          </Text>
          <Text fontSize={16} fontWeight="bold" color="secondary.300">
            {admins_alerted ? 'Yes' : 'No'}
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Text fontSize={16} fontWeight="bold" color="secondary.600">
            Managers Alerted:
          </Text>
          <Text fontSize={16} fontWeight="bold" color="secondary.300">
            {managers_alerted ? 'Yes' : 'No'}
          </Text>
        </HStack>
      </Stack>
      <hr />
    </Stack>
  );
}

export default IncidentAlerted;
