import { Deployment } from 'types/api';
import * as Yup from 'yup';

export type DeploymentDetailsFormValues = {
  name: string;
  url: string;
  repository_id: string;
  repository_branch: string;
};

export const defaultValues: DeploymentDetailsFormValues = {
  name: '',
  url: '',
  repository_id: '',
  repository_branch: 'master',
};

export const schema = Yup.object().shape({
  name: Yup.string().required('Name required'),
  url: Yup.string(),
  repository_branch: Yup.string(),
});

export const parseData = (data: Deployment | undefined) => {
  if (!data) return;
  return {
    name: data.name,
    url: data.url,
    repository_id: data.repository.id.toString(),
    repository_branch: data.repository_branch,
  };
};
