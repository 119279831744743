import { useRouter } from 'hooks/useRouter';
import { Route, Switch } from 'react-router-dom';
import SystemAdd from './SystemAdd';
import SystemsOverview from './SystemsOverview';
import SystemView from './SystemView';

function Systems() {
  const {
    match: { path },
  } = useRouter();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <SystemAdd />
      </Route>
      <Route path={`${path}/:systemID`}>
        <SystemView />
      </Route>
      <Route exact path={path}>
        <SystemsOverview />
      </Route>
    </Switch>
  );
}

export default Systems;
