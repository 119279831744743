import { Flex, Text, Select, SelectProps } from '@chakra-ui/react';

function EntriesSelect(props: SelectProps) {
  return (
    <Flex alignItems="center">
      <Text fontSize={14}>Show</Text>
      <Select
        {...props}
        _focus={{ borderColor: 'primary.200' }}
        bgColor="white"
        ml={2}
        size="lg"
        fontSize={14}
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Select>
    </Flex>
  );
}

export default EntriesSelect;
