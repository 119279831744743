import ApiError from 'components/error/ApiError';
import Loading from 'components/loading/Loading';

interface ScreenLoadingProps {
  isLoading?: boolean;
  isError: boolean;
  children: React.ReactNode;
}

function ScreenLoading({
  isLoading = false,
  isError,
  children,
}: ScreenLoadingProps) {
  return isError ? <ApiError /> : isLoading ? <Loading /> : <>{children}</>;
}

export default ScreenLoading;
