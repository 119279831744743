import { Button, Flex } from '@chakra-ui/react';
import { useRouter } from 'hooks/useRouter';
import { Row } from 'react-table';

type Props = {
  /** Row object passed ny react table */
  row: Row<{ id: number }>;
};

/**
 *  Used when opening a detail screen.
 *
 * pushes id to the url
 *
 * CSS set to remove from the DOM when printing
 */
function ViewButton({ row }: Props) {
  const { push, pathname } = useRouter();

  const onClick = () => {
    push(`${pathname}/${row.original.id}`);
  };

  return (
    <Flex
      justify="flex-end"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Button
        p={6}
        color="secondary.600"
        fontSize={12}
        borderWidth={1}
        borderColor="secondary.100"
        onClick={onClick}
      >
        View
      </Button>
    </Flex>
  );
}
export default ViewButton;
