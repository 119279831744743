import * as Yup from "yup";

export type LoginFormValues = {
  email: string;
  password: string;
};

export const defaultValues: LoginFormValues = {
  email: "",
  password: "",
};

export const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required(),
  password: Yup.string().required("Password required"),
});
