import { Text, Collapse, Button, Flex } from '@chakra-ui/react';
import { useState } from 'react';

interface CollapsedTextProps {
  text: string;
  command: string;
}

function CollapsedText({ command, text }: CollapsedTextProps) {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => setShowMore(!showMore);
  return (
    <>
      <Flex w="full" justify="flex-end">
        <Button size="md" onClick={handleToggle} mt="1rem">
          Show {showMore ? 'Less' : 'Output'}
        </Button>
      </Flex>
      <Collapse
        style={{ backgroundColor: 'black', borderRadius: 10, padding: 10 }}
        startingHeight={0}
        in={showMore}
      >
        <Text color="white" fontFamily="monospace" fontSize={14}>
          ~ {command}
        </Text>
        {text.split('\n').map((line, i) => (
          <Text
            fontFamily="monospace"
            color="white"
            fontSize={14}
            lineHeight={2}
            key={i}
          >
            {line}
          </Text>
        ))}
      </Collapse>
    </>
  );
}

export default CollapsedText;
