import { HStack, Stack } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import { DeploymentViewProps } from 'types/common/props.types';
import DeploymentConnectionsForm from 'components/forms/DeploymentConnectionsForm';
import {
  DeploymentConnectionsFormValues,
  parseData,
} from 'utils/forms/deploymentConnections';
import { useRouter } from 'hooks/useRouter';
import { useGet, usePut } from 'hooks/ApiHooks';
import ViewInfoModal from 'components/modals/ViewInfoModal';

function DeploymentConnections({ deployment }: DeploymentViewProps) {
  const {
    query: { deploymentID },
  } = useRouter();
  const mutation = usePut<DeploymentConnectionsFormValues>(
    `/deployments/${deploymentID}`,
  );

  const ipData = useGet<{ public_ip: string }>('ip', `/public-ip`);
  const sshData = useGet<{ ssh_key: string }>('ssh', `/deployment-public-key`);

  return (
    <BaseContent title="Connection">
      <HStack spacing={10}>
        <ViewInfoModal
          title="Public IP Address"
          info={ipData.data?.public_ip}
        />
        <ViewInfoModal
          title="Deployment SSH Key"
          info={sshData.data?.ssh_key}
        />
      </HStack>
      <Stack spacing={8} mt={10} fontSize={16} color="secondary.600">
        <DeploymentConnectionsForm
          mutation={mutation}
          initialValues={parseData(deployment)}
          editing
        />
      </Stack>
    </BaseContent>
  );
}

export default DeploymentConnections;
