import { Flex, HStack, Text } from '@chakra-ui/react';
import ScreenLoading from 'components/app/ScreenLoading';
import { useGet } from 'hooks/ApiHooks';
import { useRouter } from 'hooks/useRouter';
import { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Deployment } from 'types/api/deployment.types';
import DeploymentCommands from './view/DeploymentCommands';
import DeploymentConnections from './view/DeploymentConnections';
import DeploymentEdit from './view/DeploymentEdit';
import DeploymentLogs from './view/DeploymentLogs';
import DeploymentsTabs from './view/DeploymentTabs';

const paths = ['', '/connection', '/commands', '/logs'];

function DeploymentView() {
  const [tabIndex, setTabIndex] = useState(0);
  const { pathname } = useRouter();

  const {
    query: { deploymentID },
    push,
  } = useRouter();

  const {
    isLoading,
    isError,
    data: deployment,
  } = useGet<Deployment>(
    ['deployment', deploymentID],
    `deployments/${deploymentID}`,
  );

  useEffect(() => {
    paths.forEach((path, index) => {
      if (pathname.includes(path)) {
        setTabIndex(index);
      }
    });
  }, [pathname]);

  const { path, url } = useRouteMatch();

  const onTabChange = (index: number) => {
    push(`${url}${paths[index]}`);
    setTabIndex(index);
  };

  const title = deployment?.name ?? '';

  return (
    <ScreenLoading isLoading={isLoading} isError={isError}>
      <Flex bgColor="white" pl={16} justify="space-between">
        <Text fontSize="3xl" fontWeight="bold" color="secondary.600">
          {title}
        </Text>
        <HStack>
          <DeploymentsTabs tabIndex={tabIndex} handleTabsChange={onTabChange} />
        </HStack>
      </Flex>

      <Switch>
        <Route exact path={`${path}`}>
          <DeploymentEdit deployment={deployment} />
        </Route>
        <Route path={`${path}/connection`}>
          <DeploymentConnections deployment={deployment} />
        </Route>
        <Route path={`${path}/commands`}>
          <DeploymentCommands deployment={deployment} />
        </Route>
        <Route path={`${path}/logs`}>
          <DeploymentLogs deployment={deployment} />
        </Route>
      </Switch>
    </ScreenLoading>
  );
}

export default DeploymentView;
