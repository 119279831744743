import logo from 'images/ordulogoGreen.svg';
import { Center, Stack, Image } from '@chakra-ui/react';
import LoginForm from 'components/forms/LoginForm';
import { useAuth } from 'hooks/useAuth';
import { useRouter } from 'hooks/useRouter';

function Login() {
  const { isAuthenticated } = useAuth();
  const { push } = useRouter();

  if (isAuthenticated()) push('/deployments');

  return (
    <Center spacing={10} h="100vh" bg="white">
      <Stack spacing={8}>
        <Image src={logo} alt="Company Logo" />
        <LoginForm />
      </Stack>
    </Center>
  );
}

export default Login;
