import { Icon, Button } from '@chakra-ui/react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useRouter } from 'hooks/useRouter';

/** Button that will navigate back to the last screen */
function BackButton() {
  const icon = <Icon as={RiArrowLeftSLine} boxSize={6} />;

  const { history } = useRouter();

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Button
      color="black"
      borderRadius={8}
      _hover={{ bgColor: 'primary.100' }}
      borderWidth={1}
      borderColor="secondary.100"
      justify="center"
      fontSize={12}
      leftIcon={icon}
      onClick={handleClick}
    >
      Back
    </Button>
  );
}

export default BackButton;
