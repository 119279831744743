import { Select, Spinner, Text } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { QueryResult } from 'types/common';

type Props<T> = {
  selectData: QueryResult<T>;
  fieldName: string;
  children?: React.ReactNode;
};

function ApiSelect<T>({ selectData, fieldName, children }: Props<T>) {
  const { isLoading, isError, data } = selectData;
  const [field] = useField(fieldName);
  const { setFieldValue } = useFormikContext();

  const isArray = Array.isArray(data);

  useEffect(() => {
    if (Array.isArray(data) && !children && !field.value) {
      setFieldValue(fieldName, String(data[0].id));
    }
  }, [data, fieldName, children, setFieldValue, field.value]);

  if (isLoading) {
    return <Spinner color="primary.500" />;
  }

  if (isError) {
    return <Text fontSize={14}>Could not load options</Text>;
  }

  const ArrayData = () => {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        return <Text>No Data added</Text>;
      }

      return data.map(item => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ));
    }
    return null;
  };

  const ObjectData = () => {
    if (typeof data === 'object') {
      if (Object.values(data).length === 0) {
        return <Text>No Data added</Text>;
      }

      return Object.values(data).map(item => (
        <option key={item.id} value={item.id}>
          {item.name} {item?.notes}
        </option>
      ));
    }
    return null;
  };

  return (
    <Select
      {...field}
      _focus={{ borderColor: 'primary.200' }}
      bgColor="white"
      size="lg"
      fontSize={16}
      h={16}
    >
      {children}
      {isArray ? ArrayData() : ObjectData()}
    </Select>
  );
}

export default ApiSelect;
