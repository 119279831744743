import { FormikHelpers } from 'formik';
import { useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import { MutationResult } from 'types/common';
import { useHistory } from 'react-router';
import { SuccessToast, ErrorToast } from 'components/toast';

export function useSubmitHandler<T, U>(
  mutation: MutationResult<T | U>,
  cacheKey: string | string[],
  toastTitle: string,
  editing?: boolean,
  applyTransform?: (values: T) => U,
) {
  const client = useQueryClient();
  const toast = useToast();
  const history = useHistory();

  return (values: T, { setSubmitting }: FormikHelpers<T>) => {
    mutation.mutate(applyTransform ? applyTransform(values) : values, {
      onSuccess: () => {
        setSubmitting(false);

        if (Array.isArray(cacheKey)) {
          cacheKey.forEach(key => client.invalidateQueries(key));
        } else {
          client.invalidateQueries(cacheKey);
        }

        toast({
          render: ({ onClose }) => (
            <SuccessToast
              onClose={onClose}
              name={toastTitle}
              event={editing ? 'updated' : 'added'}
            />
          ),
        });
        if (!editing) {
          history.goBack();
        }
      },
      onError: error => {
        setSubmitting(false);
        const { message } = error.response?.data ?? '';
        toast({
          render: ({ onClose }) => (
            <ErrorToast onClose={onClose} message={message} />
          ),
        });
      },
    });
  };
}
