import { HStack, Stack, Text } from '@chakra-ui/react';
import { Restaurant } from 'types/common';

type Props = {
  data: Restaurant;
};

function IncidentRestaurant({ data }: Props) {
  return (
    <Stack>
      <Stack color="secondary.600" fontSize="16" p="12" align="left">
        <HStack spacing={52}>
          <Text fontSize={16} fontWeight="bold" color="secondary.600">
            Restaurant:
          </Text>
          <Text fontSize={14}>{data.name}</Text>
        </HStack>
      </Stack>
    </Stack>
  );
}

export default IncidentRestaurant;
