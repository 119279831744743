import { Icon } from '@chakra-ui/react';
import { AiOutlineDeliveredProcedure, AiOutlineTag } from 'react-icons/ai';
import { MdSettingsSystemDaydream } from 'react-icons/md';

export function renderIcon(route: string, active = false) {
  const boxSize = '2rem';
  const color = active ? 'white' : 'sidebar';
  switch (route) {
    case '/deployments':
      return <Icon as={AiOutlineDeliveredProcedure} {...{ boxSize, color }} />;
    case '/systems':
      return <Icon as={MdSettingsSystemDaydream} {...{ boxSize, color }} />;
    default:
      return <Icon as={AiOutlineTag} boxSize={boxSize} color={color} />;
  }
}
