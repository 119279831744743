import { Center, Spinner, Text, VStack } from "@chakra-ui/react";

/** Default loading spinner used during network request */
function Loading() {
  return (
    <Center mt={10}>
      <VStack>
        <Spinner boxSize={20} color="primary.500" emptyColor="gray.200" />
        <Text fontSize={12}>Loading...</Text>
      </VStack>
    </Center>
  );
}

export default Loading;
