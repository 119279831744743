import { Stack } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import DeploymentDetailsForm from 'components/forms/DeploymentDetailsForm';
import { usePut } from 'hooks/ApiHooks';
import { useRouter } from 'hooks/useRouter';
import { DeploymentViewProps } from 'types/common';
import {
  DeploymentDetailsFormValues,
  parseData,
} from 'utils/forms/deploymentDetails';

function DeploymentEdit({ deployment }: DeploymentViewProps) {
  const {
    query: { deploymentID },
  } = useRouter();
  const mutation = usePut<DeploymentDetailsFormValues>(
    `/deployments/${deploymentID}`,
  );

  return (
    <BaseContent title="Details">
      <Stack spacing={8} mt={10} fontSize={16} color="secondary.600">
        <DeploymentDetailsForm
          mutation={mutation}
          initialValues={parseData(deployment)}
          editing
        />
      </Stack>
    </BaseContent>
  );
}

export default DeploymentEdit;
