import { Stack, HStack, Switch } from '@chakra-ui/react';
import TitleWithTooltip from 'components/tooltip/TitleWithTooltip';
import { useField } from 'formik';

type Props = {
  title?: string;
  fieldName: string;
  info: string | undefined;
};

function CommonSwitch({
  fieldName,
  info = undefined,
  title = 'Overlap',
}: Props) {
  const [field] = useField(fieldName);

  return (
    <Stack>
      <TitleWithTooltip label={title} info={info} />
      <HStack alignItems="center">
        <Switch
          {...field}
          size="lg"
          colorScheme="primary"
          isChecked={field.value}
        />
      </HStack>
    </Stack>
  );
}

export default CommonSwitch;
