import { Stack } from '@chakra-ui/react';
import { SettingsData } from 'types/api';
import { SettingInput } from 'components/inputs/SettingInput';
import { SettingGroupsEnums } from 'types/states/SettingGroupsEnums';

type ThemeSettingsProps = {
  settings?: SettingsData[];
};

function ThemeSettings({ settings }: ThemeSettingsProps) {
  return (
    <Stack pt={12} pr={12} pb={0} pl={12}>
      {settings &&
        settings?.map((setting, index) => {
          if (setting.id && setting.group_id == SettingGroupsEnums.Theme) {
            return (
              <SettingInput settingProps={settings[index]} key={setting.id} />
            );
          }
        })}
    </Stack>
  );
}
export default ThemeSettings;
