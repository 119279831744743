import { Stack } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import DeploymentDetailsForm from 'components/forms/DeploymentDetailsForm';
import { usePost } from 'hooks/ApiHooks';
import { DeploymentDetailsFormValues } from 'utils/forms/deploymentDetails';

function DeploymentAdd() {
  const mutation = usePost<DeploymentDetailsFormValues>('/deployments');
  const name = 'Add Deployment';
  return (
    <BaseContent title={name}>
      <Stack spacing={8} mt={10} fontSize={16} color="secondary.600">
        <DeploymentDetailsForm mutation={mutation} />
      </Stack>
    </BaseContent>
  );
}

export default DeploymentAdd;
