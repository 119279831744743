import { useToast } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import ScreenLoading from 'components/app/ScreenLoading';
import AddButton from 'components/buttons/AddButton';
import EditButton from 'components/buttons/EditButton';
import PrimaryTable from 'components/table/PrimaryTable';
import { ErrorToast, SuccessToast } from 'components/toast';
import { useGet } from 'hooks/ApiHooks';
import { useAxios } from 'hooks/useAxios';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Column } from 'react-table';
import { Deployment } from 'types/api';
import { formatDateTime } from 'utils/dateUtils';

function DeploymentsOverview() {
  const name = 'Deployments';

  const { post } = useAxios();
  const client = useQueryClient();
  const toast = useToast();

  const { isLoading, isError, data } = useGet<Deployment[]>(
    'deployments',
    '/deployments',
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const postURL = (url: string) => post(url, null);
  const getUpdateURL = (deploymentID: number) =>
    `/deployments/${deploymentID}/update`;

  const onUpdateHandler = (deployments: Deployment[]) => {
    const deploymentURLs = deployments
      .map((deployment: Deployment) => getUpdateURL(deployment.id))
      .map(postURL);

    setIsUpdating(true);

    Promise.allSettled(deploymentURLs).then(results => {
      client.invalidateQueries('deployments');
      setIsUpdating(false);

      const allFulfilled = results.every(
        ({ status }) => status === 'fulfilled',
      );

      const allRejected = results.every(({ status }) => status === 'rejected');
      const someRejected = results.some(({ status }) => status === 'rejected');

      if (allFulfilled) {
        toast({
          render: ({ onClose }) => (
            <SuccessToast
              onClose={onClose}
              name="All"
              event="updates successful"
            />
          ),
        });
      } else if (allRejected || someRejected) {
        const errorMessage = (allRejected ? 'All' : 'Some') + ' updates failed';
        toast({
          render: ({ onClose }) => (
            <ErrorToast onClose={onClose} message={errorMessage} />
          ),
        });
      }
    });
  };

  const columns = React.useMemo<Column<Deployment>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'URL',
        accessor: 'url',
      },
      {
        Header: 'Repository',
        accessor: row => {
          return row.repository.name;
        },
      },
      {
        Header: 'Last Updated',
        accessor: 'last_updated',
        Cell: row => (row.value ? formatDateTime(row.value) : null),
      },
      {
        Header: 'Branch',
        accessor: 'repository_branch',
      },
      {
        Header: 'Validated',
        accessor: 'validated',
        Cell: row => (row.value ? 'Valid' : 'Invalid'),
      },
      {
        Header: 'Last Validated',
        accessor: 'last_validated',
        Cell: row => (row.value ? formatDateTime(row.value) : null),
      },
      {
        id: 'edit',
        disableSortBy: true,
        Cell: EditButton,
      },
    ],
    [],
  );

  const tableData = useMemo(() => data, [data]);

  return (
    <BaseContent title={name} printable>
      <ScreenLoading isLoading={isLoading} isError={isError}>
        <PrimaryTable
          mt={10}
          filterTitle={name}
          columns={columns}
          data={tableData ?? []}
          rowSelectionEnabled
          rowSelectionAction={onUpdateHandler}
          rowSelectionActionLoading={isUpdating}
        >
          <AddButton />
        </PrimaryTable>
      </ScreenLoading>
    </BaseContent>
  );
}
export default DeploymentsOverview;
