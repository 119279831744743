import React from 'react';
import {
  BoxProps,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Icon,
} from '@chakra-ui/react';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import NoData from 'components/table/NoData';
import {
  Column,
  useFilters,
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
} from 'react-table';
import GlobalSearchFilter from 'components/filters/GlobalSearchFilter';

type Props<TableData extends Partial<TableData>> = {
  /** Title used in the global filter box  */
  filterTitle?: string;

  /** Column headers for the table */
  columns: Column<TableData>[];

  /** The data to be displayed */
  data: TableData[];

  searchValue: string;

  controlledCount: number;

  onSearchChange: (value: string) => void;

  children?: React.ReactNode;
} & BoxProps;

function PaginatedTable<TableData extends Partial<TableData>>({
  filterTitle,
  columns,
  data,
  searchValue,
  onSearchChange,
  controlledCount,
  children,
  ...props
}: Props<TableData>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
  } = useTable<TableData>(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  return (
    <Box
      boxShadow="md"
      borderRadius={10}
      pt={4}
      bgColor="white"
      sx={{
        '@media print': {
          boxShadow: 'none',
        },
      }}
      {...props}
    >
      <GlobalSearchFilter
        title={filterTitle}
        globalFilter={searchValue}
        setGlobalFilter={onSearchChange}
        fontSize={14}
      />

      {children}
      <Table {...getTableProps()} fontSize={14} variant="simple">
        <Thead>
          {headerGroups.map(headerGroup => {
            const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
            return (
              <Tr {...headerProps} key={key}>
                {headerGroup.headers.map(column => {
                  const { key, ...columnProps } = column.getHeaderProps(
                    column.getSortByToggleProps(),
                  );
                  return (
                    <Th
                      {...columnProps}
                      key={key}
                      cursor="pointer"
                      fontSize={14}
                      fontFamily="Open Sans"
                      textTransform="capitalize"
                      whiteSpace="nowrap"
                    >
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon ml={4} as={TiArrowSortedDown} />
                        ) : (
                          <Icon ml={4} as={TiArrowSortedUp} />
                        )
                      ) : null}
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {pageCount === 0 ? <NoData span={columns.length} /> : null}
          {page.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => {
                  const { key } = cell.getCellProps();
                  return (
                    <Td {...cell.getCellProps()} key={key} whiteSpace="nowrap">
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}

export default PaginatedTable;
