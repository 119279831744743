import { Center, Text } from '@chakra-ui/react';

type Props = {
  /** Error message to display  */
  message?: string;
};

/**
 * Used in the event of a problem with a network request
 *
 * message default: 'We're sorry. Please check your network connection and try again'
 *  */
function ApiError({
  message = "We're sorry. Please check your network connection and try again.",
}: Props) {
  return (
    <Center mt={10} p={4} bgColor="white" w="full" borderRadius={10}>
      <Text fontSize={16}>{message}</Text>
    </Center>
  );
}

export default ApiError;
