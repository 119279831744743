import ScreenLoading from 'components/app/ScreenLoading';
import IncidentAlerted from 'components/displayIncident/IncidentAlerted';
import IncidentHeader from 'components/displayIncident/IncidentHeader';
import IncidentMetadata from 'components/displayIncident/IncidentMetadata';
import IncidentRestaurant from 'components/displayIncident/IncidentRestaurant';
import IncidentUser from 'components/displayIncident/IncidentUser';
import { useGet } from 'hooks/ApiHooks';
import { Incident } from 'types/api/incidents.types';

type Props = {
  systemID: string;
  incidentID: string;
};

function DisplayIncident({ systemID, incidentID }: Props) {
  const { isError, isLoading, data } = useGet<Incident>(
    ['incident', incidentID],
    `systems/${systemID}/incidents/${incidentID}`,
  );

  return (
    <ScreenLoading isError={isError} isLoading={isLoading}>
      {data && <IncidentHeader data={data} />}
      {data?.restaurant && <IncidentRestaurant data={data.restaurant} />}
      {data?.user && <IncidentUser user={data.user} />}
      {data && <IncidentAlerted data={data} />}
      {data?.metadata && <IncidentMetadata metadata={data.metadata} />}
    </ScreenLoading>
  );
}

export default DisplayIncident;
