import { Text, Stack } from '@chakra-ui/react';

type Props = {
  metadata: string;
};

function IncidentMetadata({ metadata }: Props) {
  return (
    <Stack p={12} justify="space-between">
      <Text fontSize={16} fontWeight="bold" color="secondary.600">
        Metadata:
      </Text>
      <Text fontSize={14}>{metadata}</Text>
    </Stack>
  );
}

export default IncidentMetadata;
