import { useRouter } from 'hooks/useRouter';
import { Route, Switch } from 'react-router-dom';
import DeploymentAdd from './DeploymentAdd';
import DeploymentsOverview from './DeploymentsOverview';
import DeploymentView from './DeploymentView';

function Deployments() {
  const {
    match: { path },
  } = useRouter();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <DeploymentAdd />
      </Route>
      <Route path={`${path}/:deploymentID`}>
        <DeploymentView />
      </Route>
      <Route exact path={path}>
        <DeploymentsOverview />
      </Route>
    </Switch>
  );
}
export default Deployments;
