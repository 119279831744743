import { ButtonProps, Button } from '@chakra-ui/react';

type StandardButtonProps = {
  title: string;
} & ButtonProps;

function StandardButton({ title, ...props }: StandardButtonProps) {
  return (
    <Button
      bgGradient="linear(to-t, #07D097, #06B885)"
      color="white"
      fontSize={14}
      p={6}
      _hover={{ bgColor: 'primary.400', color: 'white' }}
      {...props}
    >
      {title}
    </Button>
  );
}

export default StandardButton;
