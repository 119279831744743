import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header, Sidebar } from 'components/app';
import { Stack } from '@chakra-ui/react';
import Login from 'pages/login/Login';
import AppRoutes from 'AppRoutes';

function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSideBar = () => {
    setSidebarOpen(s => !s);
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />

        <Route>
          <Stack direction="row" flex={1} minH="full" spacing={0}>
            <Sidebar open={sidebarOpen} />
            <Stack direction="column" flex="1" spacing={0}>
              <Header toggle={toggleSideBar} />
              <AppRoutes />
            </Stack>
          </Stack>
        </Route>
      </Switch>
    </Router>
  );
}

export default Layout;
