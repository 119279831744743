import { SettingsData } from 'types/api';
import * as Yup from 'yup';

export type SettingsFormValues = {
  primary_color?: string | null;
  currency_symbol?: string | null;
  privacy_policy_url?: string | null;
  single_restaurant?: boolean;
  accept_vouchers?: boolean;
};

const defaultValues: SettingsFormValues = {
  primary_color: '',
  currency_symbol: '',
  privacy_policy_url: '',
  accept_vouchers: false,
  single_restaurant: false,
};

export const schema = Yup.object().shape({
  primary_color: Yup.string()
    .matches(/^#(?:[0-9a-fA-F]{3}){1,2}$/i, 'Invalid hexadecimal value')
    .nullable(),
  currency_symbol: Yup.string().nullable(),
  privacy_policy_url: Yup.string().nullable(),
  single_restaurant: Yup.boolean(),
  accept_vouchers: Yup.boolean(),
});

export const parseImageData = (data: SettingsData[]) => {
  const parsed: { [key: string]: string | null } = {};
  data
    .filter(el => el.type === 'image')
    .forEach(el => {
      parsed[el.key] = el.value;
    });

  return parsed as State;
};

export const parseData = (data: SettingsData[] | undefined) => {
  if (!data) return defaultValues;
  const parsed: { [key: string]: string | boolean | null } = {};

  data
    .filter(el => el.type !== 'image')
    .forEach(el => {
      if (el.type === 'boolean') {
        parsed[el.key] = Boolean(Number(el.value));
      } else {
        parsed[el.key] = el.value;
      }
    });

  return parsed as SettingsFormValues;
};

export const actions = {
  ALL: 'ALL',
  LOGO: 'app_logo',
  PLACEHOLDER: 'placeholder_image',
  LANDING: 'app_landing_image',
  WEBLANDING: 'website_landing_image',
  BACKGROUND: 'app_background',
};

export const intitalState: State = {
  app_logo: null,
  placeholder_image: null,
  app_landing_image: null,
  website_landing_image: null,
  app_background: null,
};

export type State = {
  app_logo: string | null;
  placeholder_image: string | null;
  website_landing_image: string | null;
  app_landing_image: string | null;
  app_background: string | null;
};

export type ACTION =
  | { type: 'ALL'; payload: State }
  | { type: 'LOGO'; app_logo: string | null }
  | { type: 'WEBLANDING'; website_landing_image: string | null }
  | { type: 'PLACEHOLDER'; placeholder_image: string | null }
  | { type: 'LANDING'; app_landing_image: string | null }
  | { type: 'BACKGROUND'; app_background: string | null };

export const imageReducer = (state: State, action: ACTION) => {
  switch (action.type) {
    case 'ALL':
      return {
        ...action.payload,
      };
    case 'LOGO':
      return {
        ...state,
        app_logo: action.app_logo,
      };
    case 'PLACEHOLDER':
      return {
        ...state,
        placeholder_image: action.placeholder_image,
      };
    case 'WEBLANDING':
      return {
        ...state,
        website_landing_image: action.website_landing_image,
      };
    case 'LANDING':
      return {
        ...state,
        app_landing_image: action.app_landing_image,
      };
    case 'BACKGROUND':
      return {
        ...state,
        app_background: action.app_background,
      };
    default:
      throw new Error('Unknown imageReducer action');
  }
};
