import { Formik, Form, FormikHelpers } from 'formik';
import { Stack, Text, Icon, chakra } from '@chakra-ui/react';
import { FiMail } from 'react-icons/fi';
import { AiOutlineLock } from 'react-icons/ai';
import axios, { AxiosResponse } from 'axios';
import { useAuth } from 'hooks/useAuth';
import { config } from 'config';
import { useState } from 'react';
import SubmitButtton from 'components/buttons/SubmitButton';
import LoginInput from 'components/inputs/LoginInput';
import { defaultValues, schema, LoginFormValues } from 'utils/forms/login';
import { AuthState } from 'types/api';

const StyledForm = chakra(Form);

function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { setAuthState } = useAuth();

  const url = config.apiUrl + '/login';

  const login = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>,
  ) => {
    setError(null);
    setIsLoading(true);
    try {
      const response = await axios.post<
        LoginFormValues,
        AxiosResponse<AuthState>
      >(url, values, {
        headers: {
          access: config.accessKey,
        },
      });
      const result = response.data;
      setSubmitting(false);
      setAuthState({ token: result.token, token_expiry: result.token_expiry });
    } catch (err) {
      setIsLoading(false);
      setSubmitting(false);
      setError(err.response.data.message);
    }
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={schema}
      onSubmit={login}
    >
      {({ values }) => (
        <StyledForm w="full">
          <Stack spacing={4} justify="center" align="center">
            <LoginInput
              icon={<Icon boxSize="6" color="secondary.200" as={FiMail} />}
              config={{ name: 'email', type: 'email', placeholder: 'Email' }}
            />
            <LoginInput
              icon={
                <Icon boxSize="6" color="secondary.200" as={AiOutlineLock} />
              }
              config={{
                name: 'password',
                type: 'password',
                placeholder: 'Password',
              }}
            />

            <SubmitButtton
              title="Sign In"
              textTransform="uppercase"
              w="80%"
              borderRadius="full"
              color="white"
              fontSize="2xl"
              boxShadow="md"
              isDisabled={!values.email || !values.password}
              border="none"
              isLoading={isLoading}
              p={8}
              _hover={{ bgColor: 'primary.600', color: 'white' }}
            />

            {error && (
              <Text fontSize={12} color="red.500">
                {error}
              </Text>
            )}
          </Stack>
        </StyledForm>
      )}
    </Formik>
  );
}

export default LoginForm;
