import { Button, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { AiOutlineEdit } from 'react-icons/ai';
import { useRouter } from 'hooks/useRouter';
import { Row } from 'react-table';

type Props<T extends { id: number | string }> = {
  /** Row object provided by react table  */
  row: Row<T>;

  text?: string;
};

/**
 * Used only on table rows
 *
 * appends the id of the this row item to the url
 *
 * */
function EditButton<T extends { id: number | string }>({ row }: Props<T>) {
  const {
    push,
    match: { url },
  } = useRouter();

  const clicked = () => {
    push(`${url}/${row.original.id}`);
  };

  return (
    <Flex justify="flex-end">
      <Button
        p={6}
        onClick={clicked}
        borderWidth={1}
        borderColor="secondary.100"
      >
        <HStack color="secondary.600">
          <Text fontSize={12}>Edit</Text>
          <Icon as={AiOutlineEdit} boxSize={6} />
        </HStack>
      </Button>
    </Flex>
  );
}

export default EditButton;
