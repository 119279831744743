import { System } from 'types/api';
import * as Yup from 'yup';

export type SystemFormValues = {
  name: string;
  deployment_id: number;
  description: string;
  code: string;
};
export type SystemFormValuesEdit = Omit<SystemFormValues, 'code'>;

export const defaultValues: SystemFormValues = {
  name: '',
  deployment_id: -1,
  description: '',
  code: '',
};

export const schema = Yup.object().shape({
  name: Yup.string().required('Name required'),
  deployment_id: Yup.number().min(0, 'Deployment required'),
});

export const parseData = (data: System | undefined): SystemFormValues => {
  if (!data) return defaultValues;
  return {
    name: data.name,
    deployment_id: data.deployment.id,
    description: data.description ?? '',
    code: data.code ?? '',
  };
};

export const convertForApi = (
  values: SystemFormValues,
): SystemFormValuesEdit => {
  return {
    name: values.name,
    deployment_id: values.deployment_id,
    description: values.description,
  };
};
