import { Stack } from '@chakra-ui/react';
import TitleWithTooltip from 'components/tooltip/TitleWithTooltip';
import SettingsImageUploader from 'components/imageUploaders/SettingImageUploader';
import { State, ACTION } from 'utils/formUtils/settings';

type ImageSettingProps = {
  state: State;
  systemID: string;
  dispatch: React.Dispatch<ACTION>;
};

function ImageSettings({ state, systemID, dispatch }: ImageSettingProps) {
  return (
    <Stack spacing={10} p={12}>
      <Stack fontSize={14}>
        <TitleWithTooltip
          label="App Logo"
          info="Set the logo for your website and app"
        />
        <SettingsImageUploader
          image={state.app_logo}
          setImage={image => dispatch({ type: 'LOGO', app_logo: image })}
          systemID={systemID}
        />
      </Stack>
      <Stack fontSize={14}>
        <TitleWithTooltip
          label="Web Landing Image"
          info="Set the landing image of your website"
        />
        <SettingsImageUploader
          image={state.website_landing_image}
          setImage={image =>
            dispatch({
              type: 'WEBLANDING',
              website_landing_image: image,
            })
          }
          systemID={systemID}
        />
      </Stack>
      <Stack fontSize={14}>
        <TitleWithTooltip
          label="Placeholder Image"
          info="Used as a placeholder image when products do not have images"
        />
        <SettingsImageUploader
          image={state.placeholder_image}
          setImage={image =>
            dispatch({
              type: 'PLACEHOLDER',
              placeholder_image: image,
            })
          }
          systemID={systemID}
        />
      </Stack>
      <Stack fontSize={14}>
        <TitleWithTooltip label="App Landing Image" info="App Landing Image" />
        <SettingsImageUploader
          image={state.app_landing_image}
          setImage={image =>
            dispatch({
              type: 'LANDING',
              app_landing_image: image,
            })
          }
          systemID={systemID}
        />
      </Stack>
    </Stack>
  );
}
export default ImageSettings;
