import { TabList, Tab } from '@chakra-ui/react';

function SettingsTabList() {
  return (
    <TabList>
      <Tab
        _selected={{ color: 'white', bg: 'primary.500' }}
        bgColor="white"
        fontSize={16}
        mr={6}
        borderTopRadius={10}
      >
        General
      </Tab>
      <Tab
        _selected={{ color: 'white', bg: 'primary.500' }}
        bgColor="white"
        fontSize={16}
        mr={6}
        borderTopRadius={10}
      >
        Theme
      </Tab>

      <Tab
        _selected={{ color: 'white', bg: 'primary.500' }}
        bgColor="white"
        fontSize={16}
        mr={6}
        borderTopRadius={10}
      >
        Integrations
      </Tab>

      <Tab
        _selected={{ color: 'white', bg: 'primary.500' }}
        bgColor="white"
        fontSize={16}
        mr={6}
        borderTopRadius={10}
      >
        Payments & Transfers
      </Tab>
      <Tab
        _selected={{ color: 'white', bg: 'primary.500' }}
        bgColor="white"
        fontSize={16}
        mr={6}
        borderTopRadius={10}
      >
        Testing
      </Tab>
    </TabList>
  );
}
export default SettingsTabList;
