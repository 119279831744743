import { extendTheme } from '@chakra-ui/react';

const OrderStatus = {
  baseStyle: {
    padding: 3,
    borderRadius: '2xl',
    textAlign: 'center',
  },
  variants: {
    1: {
      bgColor: 'red.100',
      color: 'red.600',
    },
    2: {
      bgColor: 'orange.100',
      color: 'orange.600',
    },
    3: {
      bgColor: 'primary.100',
      color: 'primary.600',
    },
    4: {
      bgColor: 'red.100',
      color: 'red.600',
    },
  },
  defaultProps: {
    variant: 'completed',
  },
};

export default extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: 'Open Sans',
      },
    },
  },
  colors: {
    background: 'rgb(248, 250, 251)',
    secondaryBackground: 'rgb(24, 24, 24)',
    paginationBg: '#07D097',
    sidebar: '#5f5f5f',
    transparent: 'transparent',
    primary: {
      50: '#e2fbf0',
      100: '#c2eada',
      200: '#a1dbc2',
      300: '#7eccab',
      400: '#5bbd92',
      500: '#42a479',
      600: '#317f5e',
      700: '#215b42',
      800: '#0f3827',
      900: '#001509',
    },
    secondary: {
      50: '#ebf3fd',
      100: '#d0d9e3',
      200: '#b4bfcb',
      300: '#97a6b5',
      400: '#7a8c9f',
      500: '#607385',
      600: '#4a5968',
      700: '#34404b',
      800: '#1d262f',
      900: '#010e15',
    },
  },
  components: {
    OrderStatus,
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
