import { Button, ButtonProps } from '@chakra-ui/react';

type Props = {
  /** Title to display on Button */
  title: string;
} & ButtonProps;

/**
 * Default green button used throughout app with 'type' set to submit
 *
 * accepts all valid chakra button props
 *  */
function SubmitButtton({ title, ...props }: Props) {
  return (
    <Button
      type="submit"
      bgGradient="linear(to-t, #07D097, #06B885)"
      color="white"
      fontSize="3XL"
      p={6}
      _hover={{ bgColor: 'primary.400', color: 'white' }}
      {...props}
    >
      {title}
    </Button>
  );
}

export default SubmitButtton;
