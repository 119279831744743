import { BaseContent } from 'components/app';
import ScreenLoading from 'components/app/ScreenLoading';
import ViewButton from 'components/buttons/ViewButton';
import PaginatedTable from 'components/table/PaginatedTable';
import PaginationControls from 'components/table/PaginationControls';
import { usePaginatedData } from 'hooks/ApiHooks';
import { useRouter } from 'hooks/useRouter';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Incident } from 'types/api/incidents.types';

function SystemIncidents() {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');

  const {
    query: { systemID },
  } = useRouter();

  const url = `/systems/${systemID}/incidents?from=${
    pageIndex * pageSize
  }&amount=${pageSize}&search=${search}`;

  const {
    isError,
    isLoading,
    data: requestData,
    isFetching,
  } = usePaginatedData<Incident[]>(
    ['incidents', String(pageIndex), String(pageSize), search],
    url,
  );

  const name = 'Incidents';

  const columns = useMemo<Column<Incident>[]>(
    () => [
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      {
        Header: 'Type',
        accessor: 'type_name',
      },
      {
        Header: 'Severity',
        accessor: 'severity_id',
      },
      {
        Header: 'Date',
        accessor: 'created_at',
      },
      {
        disableSortBy: true,
        id: 'viewColumn',
        Cell: ViewButton,
      },
    ],
    [],
  );

  const pageCount =
    Math.ceil(Number(requestData?.headers?.result_count) / pageSize) ?? 0;

  const tableData = useMemo(() => requestData?.data, [requestData?.data]);

  return (
    <BaseContent title="Incidents">
      <ScreenLoading isError={isError} isLoading={isLoading}>
        <PaginatedTable
          mt={10}
          filterTitle={name}
          columns={columns}
          controlledCount={pageCount}
          searchValue={search}
          onSearchChange={setSearch}
          data={tableData ?? []}
        />
        <PaginationControls
          {...{
            pageCount,
            pageIndex,
            pageSize,
            setPageIndex,
            setPageSize,
            isFetching,
          }}
        />
      </ScreenLoading>
    </BaseContent>
  );
}

export default SystemIncidents;
