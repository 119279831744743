import { Text, Image, Stack, HStack, Box } from '@chakra-ui/react';
import SidebarLink from 'components/links/SidebarLink';
import { motion } from 'framer-motion';
import logo from 'images/Ordu-O.png';

const OuterContainer = motion(Box);
const InnerContainer = motion(Box);

const transition = {
  when: 'afterChildren',
  bounce: 0,
  easing: 'easeInOut',
};

/** Motion container variant */
const containerVariants = {
  open: {
    width: '250px',
    transition,
  },
  closed: {
    width: '0px',
    transition,
  },
};

/** Motion link variants */
const linkVariants = {
  open: {
    x: '0%',
    transition: {
      bounce: 0,
    },
  },
  closed: {
    x: '-100%',
    transition: {
      bounce: 0,
    },
  },
};

/** Header of the sidebar containing company name and logo */
const SidebarHeader = () => {
  return (
    <HStack ml="2rem" p={4} spacing={6}>
      <Image fit="contain" boxSize={16} src={logo} alt="Company Logo" />
      <Text color="sidebar" fontSize="3xl">
        Ordú
      </Text>
    </HStack>
  );
};

type Props = {
  /** Is the sidebar open */
  open: boolean;
};

/** Component renders the sidebar */
export function Sidebar({ open }: Props) {
  return (
    <OuterContainer
      bgColor="rgb(24, 24, 24)"
      animate={open ? 'open' : 'closed'}
      initial="open"
      variants={containerVariants}
    >
      <InnerContainer
        minW="250px"
        position="sticky"
        top="0"
        animate={open ? 'open' : 'closed'}
        variants={linkVariants}
      >
        <SidebarHeader />

        <Stack spacing={4}>
          <SidebarLink name="Deployments" to="/deployments" />
          <SidebarLink name="Systems" to="/systems" />
        </Stack>
      </InnerContainer>
    </OuterContainer>
  );
}
