import React, { useState } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  InputProps,
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useAsyncDebounce } from 'react-table';

type Props = {
  /** Name if the itmes being filtered */
  title?: string;

  /** Current value of the filter */
  globalFilter: string;

  /** Function to update the value */
  setGlobalFilter: (value: string) => void;
} & InputProps;

/** Search box used on REact table instances */
function GlobalSearchFilter({
  title,
  globalFilter,
  setGlobalFilter,
  ...props
}: Props) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value);
  }, 200);

  return (
    <InputGroup m={4} w="30%" alignItems="center">
      <InputLeftElement h={16} pointerEvents="none">
        <Icon as={AiOutlineSearch} color="secondary.100" boxSize={8} />
      </InputLeftElement>
      <Input
        {...props}
        borderRadius={12}
        type="text"
        h={16}
        fontSize={16}
        _focus={{ borderColor: 'primary.500' }}
        placeholder={`Search ${title}...`}
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value || '');
        }}
      />
    </InputGroup>
  );
}

export default GlobalSearchFilter;
