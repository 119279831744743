import { AxiosError } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { useAxios } from './useAxios';

export function useGet<Data, Error = AxiosError>(
  key: string | string[],
  endpoint: string,
  options?: UseQueryOptions<Data, Error>,
) {
  const { get } = useAxios();

  return useQuery<Data, Error>(
    key,
    async () => await get<Data>(endpoint),
    options,
  );
}

export function usePaginatedData<Data, Error = AxiosError>(
  key: string | string[],
  endpoint: string,
) {
  const { getPaginatedData } = useAxios();

  return useQuery<{ data: Data; headers: { result_count: string } }, Error>(
    key,
    async () => await getPaginatedData<Data>(endpoint),
    { keepPreviousData: true },
  );
}

export function usePost<Data, Error = AxiosError, R = void>(endpoint: string) {
  const { post } = useAxios();

  return useMutation<R, Error, Data>((values: Data) =>
    post<Data>(endpoint, values),
  );
}

export function usePut<Data, Error = AxiosError>(endpoint: string) {
  const { put } = useAxios();

  return useMutation<void, Error, Data>((values: Data) =>
    put<Data>(endpoint, values),
  );
}

export function usePostImage<ImageData, Variables>(endpoint: string) {
  const { postImage } = useAxios();

  return useMutation<ImageData, AxiosError, Variables>((values: Variables) =>
    postImage(endpoint, values),
  );
}
