import { SetStateAction } from 'react';
import {
  Text,
  Button,
  ButtonGroup,
  Flex,
  Spinner,
  HStack,
} from '@chakra-ui/react';
import EntriesSelect from 'components/select/EntriesSelect';

type Props = {
  /** Is the table fetching more data */
  isFetching: boolean;

  /** Set the page number */
  setPageIndex: (value: SetStateAction<number>) => void;

  /** Set the number items per page */
  setPageSize: (value: SetStateAction<number>) => void;

  /** The number of items currently displayed */
  pageSize: number;

  /** The current page index */
  pageIndex: number;

  /** Total number of pages */
  pageCount: number;
};

function PaginationControls({
  setPageIndex,
  setPageSize,
  pageSize,
  pageIndex,
  pageCount,
  isFetching,
}: Props) {
  const canGoBack = pageIndex === 0;
  const canGoForward = pageIndex + 1 === pageCount;

  return (
    <Flex
      p={4}
      align="center"
      justify="space-between"
      bg="white"
      borderRadius={10}
      boxShadow="md"
      marginTop={5}
    >
      <Flex align="center">
        <EntriesSelect
          onChange={e => {
            setPageIndex(0);
            setPageSize(Number(e.target.value));
          }}
          value={pageSize}
          w="max-content"
        />
        <Text ml={2} fontSize={14}>
          entries
        </Text>
      </Flex>

      <ButtonGroup
        borderRadius={5}
        borderWidth={1}
        borderColor="secondary.100"
        size="md"
        isAttached
      >
        <Button
          fontSize={14}
          _hover={{ bgColor: 'paginationBg', color: 'white' }}
          onClick={() => setPageIndex(0)}
          disabled={canGoBack}
        >
          {`<<`}
        </Button>
        <Button
          fontSize={14}
          _hover={{ bgColor: 'paginationBg', color: 'white' }}
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={canGoBack}
        >
          Prev
        </Button>
        <Button
          _hover={{ bgColor: 'paginationBg', color: 'white' }}
          fontSize={14}
          bgColor="paginationBg"
          color="white"
        >
          {pageIndex + 1}
        </Button>
        <Button
          fontSize={14}
          _hover={{ bgColor: 'paginationBg', color: 'white' }}
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={canGoForward}
          d={pageIndex + 2 > pageCount ? 'none' : 'block'}
        >
          {pageIndex + 2}
        </Button>
        <Button
          fontSize={14}
          _hover={{ bgColor: 'paginationBg', color: 'white' }}
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={canGoForward}
        >
          Next
        </Button>
        <Button
          fontSize={14}
          _hover={{ bgColor: 'paginationBg', color: 'white' }}
          onClick={() => setPageIndex(pageCount - 1)}
          disabled={canGoForward}
        >
          {`>>`}
        </Button>
      </ButtonGroup>
      <HStack>
        {isFetching && <Spinner color="primary.500" emptyColor="gray.200" />}
        <Text fontSize={14}>
          Page {pageIndex + 1} of {pageCount}
        </Text>
      </HStack>
    </Flex>
  );
}

export default PaginationControls;
