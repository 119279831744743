import { Button, Icon } from '@chakra-ui/react';
import { IoMdAddCircle } from 'react-icons/io';
import { useRouter } from 'hooks/useRouter';

type Props = {
  /** Title displayed on the button. Defaults to 'Add New' */
  title?: string;
};

/** Default button used when adding a new item. Appends /add to url */
function AddButton({ title = 'Add New', ...props }: Props) {
  const {
    push,
    match: { url },
  } = useRouter();

  const clicked = () => {
    push(`${url}/add`);
  };

  const icon = <Icon boxSize={8} as={IoMdAddCircle} color="white" />;
  return (
    <Button
      fontSize={14}
      p={6}
      color="white"
      leftIcon={icon}
      bgGradient="linear(to-t, #07D097, #06B885)"
      _hover={{ bgColor: 'primary.600' }}
      onClick={clicked}
      {...props}
    >
      {title}
    </Button>
  );
}

export default AddButton;
