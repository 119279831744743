import { HStack, Stack, Text } from '@chakra-ui/react';
import { User } from 'types/common';
import InfoText from 'components/text/InfoText';

type Props = {
  user: User;
};

function IncidentUser({ user }: Props) {
  return (
    <Stack>
      <Stack
        color="secondary.600"
        fontSize={16}
        p={12}
        spacing={12}
        align="left"
      >
        <Text fontWeight="bold">User</Text>
        <HStack spacing={8}>
          <InfoText
            title="Name"
            info={`${user.first_name} ${user.last_name}`}
          />
          <InfoText title="Phone" info={user.phone} />
          <InfoText title="Email" info={user.email} />
        </HStack>
      </Stack>
      <hr />
    </Stack>
  );
}

export default IncidentUser;
