import { Formik, Form } from 'formik';
import { Stack, Flex } from '@chakra-ui/react';
import SubmitButton from 'components/buttons/SubmitButton';
import {
  schema,
  defaultValues,
  DeploymentDetailsFormValues,
} from 'utils/forms/deploymentDetails';
import LabeledInput from 'components/inputs/LabeledInput';
import { FormProps } from 'types/common';
import { useSubmitHandler } from 'hooks/useSubmitHandler';
import TitleWithTooltip from 'components/tooltip/TitleWithTooltip';
import ApiSelect from 'components/select/ApiSelect';
import { useGet } from 'hooks/ApiHooks';

function DeploymentDetailsForm({
  mutation,
  initialValues = defaultValues,
  editing,
}: FormProps<DeploymentDetailsFormValues>) {
  const submitHandler = useSubmitHandler(
    mutation,
    'deployments',
    'Deployment',
    editing,
  );

  const repoData = useGet('repositories', '/repositories');


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={submitHandler}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form>
          <Stack spacing={10}>
            <Stack
              direction="row"
              p={8}
              bg="white"
              spacing={20}
              borderRadius={5}
            >
              <Stack w="50%" spacing={8}>
                <LabeledInput
                  label="Deployment Name"
                  fieldName="name"
                  required
                />

                <LabeledInput label="URL" fieldName="url" />
              </Stack>

              <Stack w="50%" spacing={8}>
                <Stack>
                  <TitleWithTooltip
                    label="Repository"
                    info="Name of repository"
                  />
                  <ApiSelect fieldName="repository_id" selectData={repoData} />
                </Stack>

                <LabeledInput fieldName="repository_branch" label="Branch" />
              </Stack>
            </Stack>
            <Flex justify="flex-end">
              <SubmitButton
                isLoading={mutation.isLoading}
                title="Save"
                isDisabled={!(isValid && dirty)}
              />
            </Flex>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default DeploymentDetailsForm;
