import { ChakraProps, FormLabel } from '@chakra-ui/react';

type Props = {
  label: string;
} & ChakraProps;

function FormLabelCustom({ label, fontSize = 16 }: Props) {
  return (
    <FormLabel
      fontSize={fontSize}
      m={0}
      color="secondary.600"
      fontWeight="bold"
    >
      {label}
    </FormLabel>
  );
}

export default FormLabelCustom;
