import FieldFormControlWrapper from 'components/wrapperUtils/FieldFormControlWrapper';
import FormInput from 'components/inputs/FormInput';
import { InputProps } from '@chakra-ui/input';
import TitleWithTooltip from 'components/tooltip/TitleWithTooltip';

type Props = {
  /** Label to use above input */
  label: string;
  /** Placeholder value */
  placeholder?: string;
  /** Field name used by Formik */
  fieldName: string;
  /** Option to set font size */
  fontSize?: number;
  /** If the input is a required field */
  required?: boolean;
  /** Tooltip */
  info?: string;
} & InputProps;

/** Default text input */
function LabeledInput({ fieldName, required, label, info, ...props }: Props) {
  return (
    <FieldFormControlWrapper {...{ fieldName, required }}>
      <TitleWithTooltip label={label} info={info} />
      <FormInput fieldName={fieldName} {...props} />
    </FieldFormControlWrapper>
  );
}

export default LabeledInput;
