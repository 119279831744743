import FieldFormControlWrapper from 'components/wrapperUtils/FieldFormControlWrapper';
import FormLabelCustom from 'components/inputs/FormLabelCustom';
import React from 'react';
import { ChakraProps } from '@chakra-ui/system';

type Props = {
  label: string;
  placeholder?: string;
  fieldName: string;
  required?: boolean;
  children: React.ReactElement;
} & ChakraProps;

function FormLabelWrapper({ children, fieldName, ...props }: Props) {
  return (
    <FieldFormControlWrapper fieldName={fieldName} {...props}>
      <FormLabelCustom {...props} />
      {React.Children.map(children, child => {
        return React.cloneElement(child, { fieldName });
      })}
    </FieldFormControlWrapper>
  );
}

export default FormLabelWrapper;
