import { Stack } from '@chakra-ui/react';
import { SettingsData } from 'types/api';
import { SettingInput } from 'components/inputs/SettingInput';
import { SettingGroupsEnums } from 'types/states/SettingGroupsEnums';

type GeneralSettingsProps = {
  settings?: SettingsData[];
};

function GeneralSettings({ settings }: GeneralSettingsProps) {
  return (
    <Stack spacing={10} p={12}>
      {settings &&
        settings?.map((setting, index) => {
          if (setting.id && setting.group_id == SettingGroupsEnums.General) {
            return (
              <SettingInput settingProps={settings[index]} key={setting.id} />
            );
          }
        })}
    </Stack>
  );
}
export default GeneralSettings;
