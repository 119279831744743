import Deployments from 'pages/deployments/Deployments';
import Systems from 'pages/systems/Systems';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

type Props = {
  children: React.ReactNode;
} & RouteProps;

const AuthRoute = ({ children, ...props }: Props) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function AppRoutes() {
  return (
    <Switch>
      <AuthRoute path="/deployments">
        <Deployments />
      </AuthRoute>
      <AuthRoute path="/systems">
        <Systems />
      </AuthRoute>
      <AuthRoute path="/">
        <Redirect to="/deployments" />
      </AuthRoute>
    </Switch>
  );
}

export default AppRoutes;
