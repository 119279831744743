import { FormControl, FormErrorMessage, Stack } from '@chakra-ui/react';
import { Field, FieldProps, getIn } from 'formik';

/**
 * A wrapper component that wraps its children in the FormControl(https://chakra-ui.com/docs/form/form-control) component.
 * This provides context such as isInvalid, isDisabled, and isRequired to form elements.
 * Renders an error message if an error is found, checked against the schema we pass the form at a higher level(https://formik.org/docs/guides/validation).
 */

type Props = {
  children: React.ReactNode;
  fieldName: string;
  required?: boolean;
};

function FieldFormControlWrapper({
  children,
  fieldName,
  required = false,
}: Props) {
  return (
    <Field name={fieldName}>
      {({ form }: FieldProps) => (
        <FormControl
          isInvalid={
            getIn(form.errors, fieldName) && getIn(form.touched, fieldName)
          }
          isRequired={required}
        >
          <Stack>
            {children}
            <FormErrorMessage fontSize={12}>
              {getIn(form.errors, fieldName)}
            </FormErrorMessage>
          </Stack>
        </FormControl>
      )}
    </Field>
  );
}

export default FieldFormControlWrapper;
