import { Field, useField } from 'formik';
import { Input, Center, InputProps } from '@chakra-ui/react';

type Props = {
  /** Field name used by Formik */
  fieldName: string;

  /** Placeholder to display before any input is added */
  placeholder?: string;

  /** HTML input type */
  type?: string;

  /** Whether the input is disabled or not */
  disabled?: boolean;
} & InputProps;

/** Standard form input control */
function FormInput({ fieldName, ...props }: Props) {
  const [field] = useField(fieldName);

  return (
    <Center w="full">
      <Input
        as={Field}
        h={16}
        fontSize={16}
        focusBorderColor="primary.500"
        {...field}
        {...props}
      />
    </Center>
  );
}

export default FormInput;
