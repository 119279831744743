import { Flex, Stack } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import SubmitButton from 'components/buttons/SubmitButton';
import LabeledInput from 'components/inputs/LabeledInput';
import ApiSelect from 'components/select/ApiSelect';
import FormLabelWrapper from 'components/wrapperUtils/FormLabelWrapper';
import { Form, Formik } from 'formik';
import { useGet, usePut } from 'hooks/ApiHooks';
import { useRouter } from 'hooks/useRouter';
import { useSubmitHandler } from 'hooks/useSubmitHandler';
import { CustomFormProps, SystemViewProps } from 'types/common';
import {
  convertForApi,
  defaultValues,
  parseData,
  schema,
  SystemFormValues,
  SystemFormValuesEdit,
} from 'utils/forms/system';

function EditForm({
  mutation,
  initialValues = defaultValues,
}: CustomFormProps<SystemFormValues, SystemFormValuesEdit>) {
  const submitHandler = useSubmitHandler(
    mutation,
    'systems',
    'Systems',
    true,
    convertForApi,
  );
  const deployments = useGet('deployments', '/deployments');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={submitHandler}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form>
          <Stack spacing={10}>
            <Stack
              direction="row"
              p={8}
              bg="white"
              spacing={20}
              borderRadius={5}
            >
              <Stack w="50%" spacing={8}>
                <LabeledInput label="Name" fieldName="name" required />

                <FormLabelWrapper
                  label="Deployment"
                  fieldName="deployment_id"
                  required
                >
                  <ApiSelect fieldName="deployment_id" selectData={deployments}>
                    <option value="-1">Select Deployment</option>
                  </ApiSelect>
                </FormLabelWrapper>
              </Stack>

              <Stack w="50%" spacing={8}>
                <LabeledInput label="Description" fieldName="description" />

                <LabeledInput label="Code" fieldName="code" isDisabled />
              </Stack>
            </Stack>

            <Flex justify="flex-end">
              <SubmitButton
                isLoading={mutation.isLoading}
                title="Save"
                isDisabled={!(isValid && dirty)}
              />
            </Flex>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function SystemEdit({ system }: SystemViewProps) {
  const {
    query: { systemID },
  } = useRouter();
  const mutation = usePut<SystemFormValuesEdit>(`/systems/${systemID}`);

  return (
    <BaseContent title="Details">
      <Stack spacing={8} mt={10} fontSize={16} color="secondary.600">
        <EditForm mutation={mutation} initialValues={parseData(system)} />
      </Stack>
    </BaseContent>
  );
}

export default SystemEdit;
