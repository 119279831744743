import axios, { AxiosResponse } from 'axios';
import { useAuth } from 'hooks/useAuth';
import { config } from 'config/config';

export function useAxios() {
  const { token } = useAuth();

  const instance = axios.create({
    baseURL: config.apiUrl,
    headers: {
      access: config.accessKey,
      Authorization: `Bearer ${token}`,
    },
  });

  const get = async <Data>(endpoint: string) => {
    const { data } = await instance.get<Data>(endpoint);
    return data;
  };

  const getPaginatedData = async <Data>(endpoint: string) => {
    const { data, headers } = await instance.get<Data>(endpoint);
    return { data, headers };
  };

  const post = async <Data>(endpoint: string, body: Data) => {
    const { data } = await instance.post(endpoint, body);
    return data;
  };

  const put = async <Data>(endpoint: string, body: Data) => {
    const { data } = await instance.put(endpoint, body);
    return data;
  };

  const deleteRequest = async <Data>(endpoint: string, data: Data) => {
    return await instance
      .delete(endpoint, { data })
      .then((response: AxiosResponse<Data>) => response.data);
  };

  const postImage = async <Data>(endpoint: string, fd: Data) => {
    const { data } = await instance.post(endpoint, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  };

  return { get, getPaginatedData, post, put, deleteRequest, postImage };
}
