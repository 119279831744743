import { useState, createContext, useContext } from 'react';
import { DateTime } from 'luxon';
import { AuthContextInterface, AuthState } from 'types/api';

const AuthContext = createContext<AuthContextInterface | null>(null);

const STORAGE_KEY = 'root';

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be rendered in the AuthProvider');
  }
  return context;
}

type Props = {
  children: React.ReactNode;
};

export function AuthProvider(props: Props) {
  const [authState, setAuthState] = useState<AuthState | null>(() => {
    const value = localStorage.getItem(STORAGE_KEY);
    return value ? JSON.parse(value) : null;
  });

  const setState = (auth: AuthState) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(auth));
    setAuthState(auth);
  };

  const logout = () => {
    localStorage.clear();
    setAuthState(null);
  };

  const isAuthenticated = () => {
    if (!authState || !authState.token || !authState.token_expiry) {
      return false;
    }

    return DateTime.now().toUTC() < DateTime.fromSQL(authState.token_expiry);
  };

  return (
    <AuthContext.Provider
      {...props}
      value={{
        ...authState,
        setAuthState: authInfo => setState(authInfo),
        isAuthenticated,
        logout,
      }}
    />
  );
}
