import { Box, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { BiErrorCircle } from 'react-icons/bi';

type Props = {
  /** Fucntion to close the popup */
  onClose: () => void;

  /** Name of the entitthat the request was made for eg, store, menu */
  message: string;
};

/** Used to notify the user of an unsuccesful network request */
export function ErrorToast({ onClose, message }: Props) {
  const description = message ? message : "We're sorry, an error has occured";
  return (
    <Box p={4} color="white" bg="red.500" borderRadius={5}>
      <Flex justify="space-between">
        <HStack>
          <Icon as={BiErrorCircle} boxSize={8} color="white" />
          <Text fontSize={16}>Error!</Text>
        </HStack>

        <Icon boxSize={5} as={AiOutlineClose} onClick={onClose} />
      </Flex>

      <Text fontSize={14}>{description}</Text>
    </Box>
  );
}
