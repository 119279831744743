import { DateTime } from 'luxon';

export const formatDate = (data: string) => {
  return DateTime.fromSQL(data).toFormat('dd/MM/yyyy');
};

export const formatDateTime = (data: string) => {
  return DateTime.fromSQL(data).toFormat('dd/MM/yyyy HH:mm');
};

export const formatTime = (data: string) => {
  return DateTime.fromSQL(data).toFormat('HH:mm');
};

export const convertDay = (value: string | number) => {
  let temp;
  if (typeof value === 'string') {
    temp = Number(value);
  } else {
    temp = value;
  }
  switch (temp) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
  }
};
