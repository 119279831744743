import {
  Box,
  Center,
  Icon,
  Image,
  Input,
  Stack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import ScreenLoading from 'components/app/ScreenLoading';
import StandardButton from 'components/buttons/StandardButton';
import { ErrorToast } from 'components/toast';
import { useGet, usePost } from 'hooks/ApiHooks';
import React, { useRef } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import { System } from 'types/api';
import { FileData } from 'types/common';

type ImageUploaderProps = {
  /** The url of this image */
  image: string | null;

  /** Function to update image. Set to the url received in the API response after updating the image */
  setImage: (image: string | null) => void;

  systemID: string;
};

function SettingsImageUploader({
  image,
  setImage,
  systemID,
}: ImageUploaderProps) {
  const { data } = useGet<System>(['system', systemID], `systems/${systemID}`);

  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const mutation = usePost<FormData, null, FileData>(
    `/systems/${systemID}/uploads`,
  );

  const removeImage = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
    setImage(null);
  };

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const image = files[0];

      const fd = new FormData();

      fd.append('file', image);

      mutation.mutate(fd, {
        onSuccess: data => {
          setImage(data.path);
        },
        onError: () => {
          toast({
            render: ({ onClose }) => (
              <ErrorToast onClose={onClose} message="Failed to upload image" />
            ),
          });
        },
      });
    }
  };

  const DisplayImage = ({ system }: { system: System }) => {
    const imagePath = system.deployment.url + image;
    return (
      <Stack spacing={4}>
        <Center
          boxSize="200px"
          bg="secondary.100"
          overflow="hidden"
          cursor="pointer"
          borderRadius={5}
        >
          <Image
            fit="contain"
            boxSize="200px"
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current.click();
              }
            }}
            src={imagePath}
          />
        </Center>
        <Box>
          <StandardButton title="Remove" onClick={removeImage} />
        </Box>
      </Stack>
    );
  };

  const DisplayUploader = () => {
    return (
      <Center
        boxSize="200px"
        borderRadius={5}
        bgColor={mutation.isLoading ? 'white' : 'secondary.100'}
      >
        <ScreenLoading
          isLoading={mutation.isLoading}
          isError={mutation.isError}
        >
          <VStack spacing={0} justify="center">
            <Icon as={AiOutlineCamera} boxSize={20} />
            <StandardButton
              title="Upload"
              onClick={() => {
                if (inputRef && inputRef.current) {
                  inputRef.current.click();
                }
              }}
            />
          </VStack>
        </ScreenLoading>
      </Center>
    );
  };

  return (
    <Stack>
      {data && image ? <DisplayImage system={data} /> : <DisplayUploader />}
      <Input
        accept="image/*"
        d="none"
        ref={inputRef}
        type="file"
        onChange={uploadImage}
      />
    </Stack>
  );
}

export default SettingsImageUploader;
