import { Stack, Input } from '@chakra-ui/react';
import { SettingsData } from 'types/api';
import LabeledInput from 'components/inputs/LabeledInput';
import CommonSwitch from 'components/switch/CommonSwitch';
import FormLabelWrapper from 'components/wrapperUtils/FormLabelWrapper';
import { Field } from 'formik';

type SettingProps = {
  settingProps: SettingsData;
};

export function SettingInput({ settingProps }: SettingProps) {
  return (
    <Stack>
      {settingProps.type == 'string' && (
        <LabeledInput
          label={settingProps.name}
          fieldName={settingProps.key}
          info={settingProps.description}
          type="string"
        />
      )}

      {(settingProps.type == 'decimal' || settingProps.type == 'integer') && (
        <LabeledInput
          label={settingProps.name}
          fieldName={settingProps.key}
          info={settingProps.description}
          type="number"
        />
      )}

      {settingProps.type == 'boolean' && (
        <CommonSwitch
          fieldName={settingProps.key}
          info={settingProps.description}
          title={settingProps.name}
        />
      )}

      {settingProps.type == 'colour' && (
        <FormLabelWrapper
          label={settingProps.name}
          fieldName={settingProps.key}
        >
          <Stack direction="row">
            <Field
              as={Input}
              w="20rem"
              name={settingProps.key}
              h={16}
              fontSize={16}
              focusBorderColor="primary.500"
            />
            <Field
              as={Input}
              w="20rem"
              name={settingProps.key}
              h={16}
              fontSize={16}
              focusBorderColor="primary.500"
              type="color"
              style={{ padding: 0 }}
            />
          </Stack>
        </FormLabelWrapper>
      )}
    </Stack>
  );
}
