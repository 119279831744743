import {
  HStack,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import ScreenLoading from 'components/app/ScreenLoading';
import SubmitButtton from 'components/buttons/SubmitButton';
import { ErrorToast, SuccessToast } from 'components/toast';
import { Form, Formik, FormikHelpers } from 'formik';
import { useGet, usePut } from 'hooks/ApiHooks';
import { useRouter } from 'hooks/useRouter';
import { useEffect, useReducer } from 'react';
import { useQueryClient } from 'react-query';
import { SettingsData } from 'types/api';
import {
  imageReducer,
  intitalState,
  parseData,
  parseImageData,
  schema,
  SettingsFormValues,
} from 'utils/formUtils/settings';
import GeneralSettings from './settings/GeneralSettings';
import ImageSettings from './settings/ImageSettings';
import IntegrationSettings from './settings/IntegrationSettings';
import PaymentsAndTransfers from './settings/PaymentsAndTransfers';
import SettingsTabList from './settings/SettingsTabList';
import TestingSettings from './settings/TestingSettings';
import ThemeSettings from './settings/ThemeSettings';

function SystemSettings() {
  const {
    query: { systemID },
  } = useRouter();

  const client = useQueryClient();
  const toast = useToast();
  const [state, dispatch] = useReducer(imageReducer, intitalState);
  const mutation = usePut(`/systems/${systemID}/settings`);

  const { isLoading, isError, data } = useGet<SettingsData[]>(
    ['settings', systemID],
    `/systems/${systemID}/settings`,
  );

  useEffect(() => {
    if (data) {
      const parsed = parseImageData(data);
      dispatch({ type: 'ALL', payload: parsed });
    }
  }, [data, dispatch]);

  const onSubmitHandler = (
    values: SettingsFormValues,
    { setSubmitting }: FormikHelpers<SettingsFormValues>,
  ) => {
    const data = {
      ...values,
      ...state,
    };

    mutation.mutate(data, {
      onSuccess: () => {
        setSubmitting(false);
        client.invalidateQueries('settings');
        client.invalidateQueries('config');
        toast({
          render: ({ onClose }) => (
            <SuccessToast onClose={onClose} name="Settings" event="updated" />
          ),
        });
      },
      onError: error => {
        setSubmitting(false);
        toast({
          render: ({ onClose }) => (
            <ErrorToast
              onClose={onClose}
              message={error.response?.data.message}
            />
          ),
        });
      },
    });
  };

  return (
    <BaseContent title="Settings">
      <ScreenLoading isError={isError} isLoading={isLoading}>
        <Stack spacing={12}>
          <Formik
            initialValues={parseData(data)}
            validationSchema={schema}
            onSubmit={onSubmitHandler}
            enableReinitialize
          >
            {() => (
              <Form>
                <Tabs isFitted varitant="enclosed">
                  <SettingsTabList />
                  <TabPanels
                    bgColor="white"
                    borderBottomRadius={10}
                    boxShadow="md"
                  >
                    <TabPanel>
                      <GeneralSettings settings={data} />
                    </TabPanel>
                    <TabPanel>
                      <ThemeSettings settings={data} />
                      <ImageSettings
                        state={state}
                        systemID={systemID}
                        dispatch={dispatch}
                      />
                    </TabPanel>
                    <TabPanel>
                      <IntegrationSettings settings={data} />
                    </TabPanel>
                    <TabPanel>
                      <PaymentsAndTransfers settings={data} />
                    </TabPanel>
                    <TabPanel>
                      <TestingSettings settings={data} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <HStack justify="flex-end" mt={8}>
                  <SubmitButtton
                    isLoading={mutation.isLoading}
                    title="Save Changes"
                  />
                </HStack>
              </Form>
            )}
          </Formik>
        </Stack>
      </ScreenLoading>
    </BaseContent>
  );
}

export default SystemSettings;
