import { HStack, Text, Stack, useToast, VStack } from '@chakra-ui/react';
import { BaseContent } from 'components/app';
import StandardButton from 'components/buttons/StandardButton';
import { ErrorToast, SuccessToast } from 'components/toast';
import { usePost } from 'hooks/ApiHooks';
import { useRouter } from 'hooks/useRouter';
import { useQueryClient } from 'react-query';
import { DeploymentViewProps } from 'types/common/props.types';

function DeploymentCommands({ deployment }: DeploymentViewProps) {
  const {
    query: { deploymentID },
  } = useRouter();
  const toast = useToast();
  const client = useQueryClient();
  const checkMutation = usePost(`/deployments/${deploymentID}/check`);
  const updateMutation = usePost(`/deployments/${deploymentID}/update`);
  const validateEnvMutation = usePost(
    `/deployments/${deploymentID}/validate-env`,
  );

  const deploymentName = deployment?.name ?? '';

  const onCheckHandler = () => {
    checkMutation.mutate(null, {
      onSuccess: () => {
        client.invalidateQueries(['deployment', deploymentID]);
        toast({
          render: ({ onClose }) => (
            <SuccessToast
              onClose={onClose}
              name={deploymentName}
              event="connected successfully"
            />
          ),
        });
      },
      onError: error => {
        const errorMessage = error.response?.data ?? '';
        toast({
          render: ({ onClose }) => (
            <ErrorToast onClose={onClose} message={errorMessage.message} />
          ),
        });
      },
    });
  };

  const onUpdateHandler = () => {
    updateMutation.mutate(null, {
      onSuccess: () => {
        toast({
          render: ({ onClose }) => (
            <SuccessToast
              onClose={onClose}
              name={deploymentName}
              event="updated successfully"
            />
          ),
        });
      },
      onError: error => {
        const errorMessage = error.response?.data ?? '';
        toast({
          render: ({ onClose }) => (
            <ErrorToast onClose={onClose} message={errorMessage.message} />
          ),
        });
      },
      onSettled: () => {
        client.invalidateQueries(['deployment', deploymentID]);
      },
    });
  };

  const onValidateEnvHandler = () => {
    validateEnvMutation.mutate(null, {
      onSuccess: () => {
        toast({
          render: ({ onClose }) => (
            <SuccessToast
              onClose={onClose}
              name={deploymentName}
              event=".env file validated successfully!"
            />
          ),
        });
      },
      onError: error => {
        const errorMessage = error.response?.data ?? '';
        toast({
          render: ({ onClose }) => (
            <ErrorToast onClose={onClose} message={errorMessage.message} />
          ),
        });
      },
      onSettled: () => {
        client.invalidateQueries(['deployment', deploymentID]);
      },
    });
  };

  return (
    <BaseContent title="Commands">
      <Stack spacing={8}>
        <Text fontSize={16} fontWeight="bold">
          Commands
        </Text>
        <HStack spacing={10}>
          <VStack bg="white" borderRadius={10} w="20%" p={4} shadow="md">
            <Text fontSize={16} fontWeight="bold">
              Check Connection
            </Text>
            <StandardButton
              title="Check"
              w="max-content"
              isLoading={checkMutation.isLoading}
              onClick={onCheckHandler}
            />
          </VStack>

          <VStack bg="white" borderRadius={10} p={4} w="20%" shadow="md">
            <Text fontSize={16} fontWeight="bold">
              Update Deployment
            </Text>
            <StandardButton
              title="Update"
              w="max-content"
              isLoading={updateMutation.isLoading}
              onClick={onUpdateHandler}
            />
          </VStack>

          <VStack bg="white" borderRadius={10} p={4} w="20%" shadow="md">
            <Text fontSize={16} fontWeight="bold">
              Validate .env file
            </Text>
            <StandardButton
              title="Validate"
              w="max-content"
              isLoading={validateEnvMutation.isLoading}
              onClick={onValidateEnvHandler}
            />
          </VStack>
        </HStack>
      </Stack>
    </BaseContent>
  );
}

export default DeploymentCommands;
